<template>
  <div>
    <v-dialog v-model="dialogs.confirmGps" max-width="400">
      <v-card v-if="dialogs.confirmMessage">
        <v-card-text>
          <div class="pt-3">
            <h2 class="text-h6">Confirm the GPS location of photo?</h2>
            <div class="text-center">
              <div>
                <CoordinatesAndOrientation
                  :confirmed="false"
                  :coordinates="formData.geolocation"
                  :orientation="formData.orientation"
                />
                <div class="mt-2">
                  <v-btn
                    color="primary"
                    class="text-none mr-3"
                    @click="dialogs.confirmMessage = false"
                  >
                    Yes
                  </v-btn>
                  <v-btn color="primary" class="text-none" outlined @click="cancelGpsConfirm()">
                    Not now
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <UpdatePhotoStation
        v-else
        :dialog.sync="dialogs.confirmGps"
        :id="parcelId"
        form-only
        @input="confirmGps"
        :value="{ geolocation: formData.geolocation, orientation: formData.orientation }"
        :geojson="geojson"
      />
    </v-dialog>
    <v-dialog v-model="dialogs.map" max-width="600">
      <UpdatePhotoStation
        :key="dialogs.map"
        :id="formData.id"
        :geojson="geojson"
        :dialog.sync="dialogs.map"
        @success="onPhotoStationUpdate"
      />
    </v-dialog>
    <v-dialog v-model="dialogs.askOrientation" max-width="300">
      <v-card class="mx-auto">
        <v-container class="pa-4">
          <h1 class="text-h6 mb-3">Can you provide a compass heading?</h1>
          <div class="d-flex justify-center">
            <lottie-player
              src="https://assets10.lottiefiles.com/packages/lf20_zdouw3jj.json"
              background="transparent"
              speed="0.7"
              style="width: auto; height: 200px"
              autoplay
            ></lottie-player>
          </div>
          <v-btn
            block
            color="primary"
            class="mb-3"
            @click="
              dialogs.askOrientation = false;
              dialogs.compass = true;
            "
            >Yes</v-btn
          >
          <v-btn block color="grey" class="ml-1" text @click="dialogs.askOrientation = false"
            >No</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.compass" persistent max-width="300">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.compass = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center">
          <Compass v-model="bearing" />
        </div>
        <v-card-text>
          <v-btn
            block
            color="primary"
            class="text-capitalize"
            :disabled="!bearing"
            @click="selectCompass()"
            >Select {{ bearing }}</v-btn
          >
          <v-btn
            block
            class="text-capitalize mt-1"
            color="grey"
            text
            @click="dialogs.compass = false"
            >Cancel</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form id="form" ref="form" v-model="valid">
      <div class="mt-5">
        <v-row>
          <v-col
            v-show="showField('firstName') && (update || !loggedIn || !formData.firstName)"
            cols="6"
          >
            <v-text-field
              v-model="formData.firstName"
              :rules="rules.requiredRule"
              outlined
              label="First Name"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="showField('lastName') && (update || !loggedIn || !formData.lastName)"
            cols="6"
          >
            <v-text-field
              v-model="formData.lastName"
              :rules="rules.requiredRule"
              outlined
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="showField('email') && (update || !loggedIn || !formData.email)"
            cols="12"
            sm="6"
            class="mt-n5"
          >
            <v-text-field
              v-model="formData.email"
              :rules="rules.emailRule"
              outlined
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="showField('category')"
            cols="12"
            :sm="!(update || !loggedIn) ? '12' : '6'"
            class="mt-n5"
          >
            <div>
              <v-autocomplete
                v-model="formData.type"
                outlined
                label="Category"
                :rules="rules.requiredRule"
                :items="categories"
              ></v-autocomplete>
              <div class="mb-3 mt-n5">
                <slot name="add-category"> </slot>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-textarea
          v-show="showField('caption')"
          v-model="formData.caption"
          outlined
          auto-grow
          :rows="2"
          label="Caption"
        ></v-textarea>
        <div>
          <v-fade-transition>
            <v-textarea
              v-show="showField('subcaption') && formData.caption"
              v-model="formData.subCaption"
              label="Subcaption"
              rows="2"
              auto-grow
              outlined
            ></v-textarea>
          </v-fade-transition>
        </div>
      </div>
      <v-fade-transition>
        <div v-show="showField('parcel') && showParcelInput">
          <v-text-field v-model="formData.parcelId" outlined label="Parcel ID"> </v-text-field>
        </div>
      </v-fade-transition>
      <v-fade-transition>
        <div>
          <div v-show="showField('artDescription') && formData.forSale">
            <div>
              <div class="tiptap-container mt-n3">
                <label for="subcaption" class="text-h6">Description</label>
                <text-editor
                  id="art-description"
                  v-model="formData.artDescription"
                  class="mt-1 mb-10"
                  placeholder="Art Description"
                ></text-editor>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <v-checkbox
              v-show="showField('privacy') && (formData.forSale || userSetting.enablePrivacy)"
              v-model="formData.private"
              class="mt-0"
              label="Private"
            ></v-checkbox>
            <v-checkbox
              v-show="showField('sold') && formData.forSale"
              v-model="formData.sold"
              class="ml-3 mt-0"
              label="Sold"
            ></v-checkbox>
          </div>
        </div>
      </v-fade-transition>
      <v-row>
        <v-col v-show="showField('hideName')" cols="8">
          <v-checkbox
            v-model="formData.hideName"
            class="mt-n1"
            label="Don't add my name under my caption"
          ></v-checkbox>
        </v-col>
        <v-col v-if="showField('forSale') && loggedIn && userSetting.sale" cols="4">
          <div class="d-flex">
            <v-checkbox v-model="formData.forSale" class="mt-n1">
              <template #label> For Sale </template>
            </v-checkbox>
            <v-btn style="margin-top: -5.5px" color="primary" icon @click="dialogs.forSale = true">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-if="showField('imageUpload') && showFileUpload">
        <image-upload
          :uploading.sync="uploading"
          :multiple="mode !== 'camera'"
          :mode="mode"
          ref="imageUpload"
          v-model="validImages"
          :required="photoRequired"
          :converting.sync="converting"
          :files.sync="formData.files"
          :ids.sync="formData.images"
          @process="onProcessFile"
        ></image-upload>
      </div>
      <slot name="footer"></slot>

      <v-scroll-y-transition>
        <v-alert v-if="success" type="success" dismissible>{{ success }}</v-alert>
      </v-scroll-y-transition>
      <v-scroll-y-transition>
        <v-alert v-if="error" type="error" dismissible>{{ error }}</v-alert>
      </v-scroll-y-transition>

      <!-- <div v-if="($store.state.gps || orientation) && userSetting.gps && !update"> -->
      <div
        class="d-flex justify-center mt-3 flex-wrap align-center"
        v-if="mode === 'camera' || update"
      >
        <div>
          <CoordinatesAndOrientation
            @click="permissionEditPhotoGps && update ? (dialogs.map = true) : {}"
            :confirmed="permissionEditPhotoGps && update ? !!formData.geoDataConfirmed : true"
            :x-small="$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.mdAndUp"
            :coordinates="formData.geolocation"
            :orientation="formData.orientation"
          />
        </div>
        <v-btn
          v-if="!update"
          :x-small="$vuetify.breakpoint.smAndDown"
          :small="$vuetify.breakpoint.mdAndUp"
          text
          color="primary lighten-3"
          class="v-btn--active ml-1"
          @click="dialogs.compass = true"
        >
          <v-icon color="primary">mdi-compass</v-icon>
        </v-btn>
      </div>
      <div v-else class="mt-6 mb-n10">
        <DateField v-model="formData.imageDate" label="Image Date" />
      </div>

      <div v-if="update && permissionEditPhotoGps" class="my-3 text-center">
        <v-btn
          color="primary"
          small
          text
          class="v-btn--active text-none mr-1"
          @click="dialogs.map = true"
          >Edit GPS Location and Bearing</v-btn
        >
      </div>
      <div class="d-flex justify-center">
        <CoordinatesAndOrientation
          :x-small="$vuetify.breakpoint.smAndDown"
          :small="$vuetify.breakpoint.mdAndUp"
          :coordinates="updateFormData.geolocation"
          :orientation="updateFormData.orientation"
        />
      </div>

      <div class="submit-container">
        <div class="d-sm-flex pb-2 pt-4">
          <v-spacer></v-spacer>

          <div class="text-center">
            <v-btn
              color="primary"
              class="text-capitalize"
              :loading="loading"
              :disabled="(converting && photoRequired) || uploading"
              @click="onSubmit()"
            >
              {{ label }}
            </v-btn>

            <v-btn class="text-capitalize ml-1" text color="grey" @click="onCancel()">
              Cancel
            </v-btn>
          </div>

          <v-spacer></v-spacer>
          <slot name="technicalDifficulty"> </slot>
        </div>
      </div>

      <PhotoStationPreview
        class="my-1"
        v-if="!update && config.photoStation"
        :geojson="geojson"
        :geolocation="formData.geolocation"
        :orientation="formData.orientation"
      />

      <div class="text-left mt-2" v-show="showFooter && mode !== 'camera'">
        <span class="light-black--text">
          <small>
            For responsive images, please keep images size between 100 KB and 500 KB if possible.
            <br />
            10 MB is maximum size that should be uploaded.
          </small>
        </span>
      </div>
    </v-form>
  </div>
</template>

<script>
import config from '../../config';
import Orientation from '../../services/orientation';
import ImageUpload from './ImageUpload.vue';
export default {
  components: {
    ImageUpload,
    TextEditor: () => import('../../components/Core/TextEditor.vue'),
    CoordinatesAndOrientation: () => import('../../components/CoordinatesAndOrientation.vue'),
    Compass: () => import('../../components/Compass.vue'),
    UpdatePhotoStation: () => import('../../components/Parcel/UpdatePhotoStation.vue'),
    PhotoStationPreview: () => import('../../components/Parcel/PhotoStationPreview.vue'),
    UpdatePhotoStation: () => import('../../components/Parcel/UpdatePhotoStation.vue'),
    DateField: () => import('../../components/Core/DateField.vue')
  },
  props: {
    // required props
    parcelId: {
      type: String,
      default: ''
    },
    geojson: {
      type: [String, Object],
      default: null
    },
    loggedIn: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    userSetting: {
      type: Object,
      required: true
    },
    galleryRef: {
      type: Object,
      required: true
    },
    photoRequired: {
      type: Boolean,
      required: true
    },

    // optional props
    gallery: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userData: {
      type: Object,
      default: null
    },
    selectedCategory: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    },
    showFileUpload: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Submit photo'
    },
    update: {
      type: Boolean,
      default: false
    },
    inputParcel: {
      type: Boolean,
      default: false
    },
    admin: {
      type: Boolean,
      default: false
    },
    website: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      config,
      rules: {
        emailRule: [
          v => !!v || 'Enter a valid email',
          v => /.+@.+\..+/.test(v) || 'Enter a valid email'
        ],
        requiredRule: [v => !!v || 'This field is required'],
        passwordRule: [v => (!!v && v.length >= 6) || 'Password should be at least 6 characters']
      },
      updateFormData: {
        geolocation: null,
        coordinates: null,
        bearing: ''
      },
      formData: {
        files: [],
        firstName: '',
        lastName: '',
        email: '',
        type: '',
        geolocation: {},
        orientation: {},
        caption: '',
        subCaption: '',
        forSale: false,
        hideName: false,
        public: true,
        private: false,
        sold: false,
        website: '',
        galleryId: 'public',
        parcelId: ''
      },
      dialogs: {
        forSale: false,
        compass: false,
        askOrientation: false,
        map: false,
        confirmGps: false,
        confirmMessage: true
      },
      bearing: null,
      orientation: null,
      valid: true,
      validImages: true,
      converting: false,
      loading: false,
      success: '',
      error: '',
      errors: {
        convert: [],
        imageRequired: false
      },
      askOrientation: false,
      uploading: false,
      showOrientation: true,
      updateGpsConfirmed: false
    };
  },
  computed: {
    permissionEditPhotoGps() {
      const editPhotoGps = this.$store.state.permissions.editPhotoGps;
      return editPhotoGps || this.$store.getters.isAdmin;
    },
    gps() {
      return this.$store.state.gps || {};
    },
    orientationComputed() {
      if (this.update) return this.data?.orientation;
      return this.orientation;
    },
    showParcelInput() {
      if (this.admin) {
        return true;
      }
      if (this.inputParcel) {
        return true;
      }
      if (this.gallery && this.gallery.inputParcel) {
        return true;
      }
      return false;
    },
    isGalleryPublic() {
      if (!this.gallery || !this.gallery.id) {
        return true;
      }
      if (String(this.gallery.id).toLowerCase() === 'public') {
        return true;
      }
      return false;
    }
  },
  watch: {
    gps: {
      handler(value) {
        if (this.update) {
        } else {
          this.formData.geolocation = value;
        }
      },
      immediate: true
    },
    gallery: {
      handler(val) {
        if (this.update) {
          return;
        }
        if (!val) {
          this.formData.galleryId = 'public';
        } else {
          this.formData.galleryId = val.id;
        }
      },
      immediate: true,
      deep: true
    },
    selectedCategory: {
      handler(val) {
        if (!this.update) {
          this.formData.type = val;
        }
      },
      immediate: true,
      deep: true
    },
    userData: {
      handler: 'setUserData',
      immediate: true,
      deep: true
    },
    data: {
      handler: 'setDefaultData',
      immediate: true,
      deep: true
    }
  },
  beforeMount() {
    this.setDefaultData();
    this.setUserData();
  },
  mounted() {
    this.checkSlots();
    if (config.orientation) {
      const orientation = new Orientation();
      setTimeout(() => {
        if (orientation.supports() && !this.$store.state.orientationPermission) {
          this.$store.commit('setDialog', { key: 'orientationPermission', value: true });
        }
      }, 2000);
    }
  },
  methods: {
    cancelGpsConfirm() {
      this.dialogs.confirmGps = false;
      setTimeout(() => {
        this.onSubmit();
      }, 100);
    },
    confirmGps(data) {
      const geolocation = data?.newGeoData?.geolocation;
      const orientation = data?.newGeoData?.orientation;
      const geoHistory = data?.geoHistory;
      this.dialogs.confirmGps = false;
      this.formData.geolocation = geolocation;
      this.formData.orientation = orientation;
      this.formData.geoHistory = geoHistory;
      this.formData.geoDataConfirmed = true;
      setTimeout(() => {
        this.onSubmit();
      }, 100);
    },
    onPhotoStationUpdate(data) {
      this.dialogs.map = false;
      const geolocation = data?.geolocation;
      const orientation = data?.orientation;
      if (geolocation) {
        this.formData.geolocation = geolocation;
      }
      if (orientation) {
        this.formData.orientation = orientation;
      }
      this.formData.geoDataConfirmed = true;
    },
    onProcessFile() {
      if (
        this.$store.state.gps?.lng &&
        !Orientation.isValidOrientation(this.formData?.orientation?.heading) &&
        !this.dialogs.compass &&
        this.mode === 'camera'
      ) {
        this.dialogs.askOrientation = true;
      }
    },
    selectCompass() {
      const orientation = new Orientation();
      if (this.update) {
        this.updateFormData.orientation = { heading: orientation.bearing360(this.bearing) };
        this.updateFormData.bearing = this.bearing;
      } else {
        this.formData.orientation = { heading: orientation.bearing360(this.bearing) };
      }
      this.dialogs.compass = false;
    },
    checkSlots() {
      const SLOTS = {
        addCategory: 'Add category component is missing',
        technicalDifficulty: 'Technical difficulty component is missing'
      };
      Object.keys(SLOTS).forEach(k => {
        if (!this.$slots[k]) {
          console.warn(SLOTS[k]);
        }
      });
    },

    setData(data) {
      if (!data || typeof data !== 'object') {
        return;
      }
      Object.keys(data).forEach(k => {
        this.formData[k] = data[k] || this.formData[k];
      });
      if (!Orientation.isValidOrientation(this.formData?.orientation?.heading)) {
        this.formData.orientation = { ...(this.$store.state.orientation || {}) };
      }
    },
    setDefaultData() {
      this.setData(this.data);
    },
    setUserData() {
      if (this.update) {
        return;
      }
      this.setData(this.userData);
    },

    async getCoordinates() {
      try {
        await this.userProfile();
        // eslint-disable-next-line no-empty
      } catch {}

      if (!this.userSetting) {
        return;
      }

      if (!this.loggedIn || !this.userSetting.gps) {
        return;
      }
    },
    submit() {},
    onSubmit() {
      if (
        !this.update &&
        !this.updateGpsConfirmed &&
        this.permissionEditPhotoGps &&
        this.mode === 'camera'
      ) {
        this.dialogs.confirmGps = true;
        this.updateGpsConfirmed = true;
        return;
      }

      this.$refs.form.validate();

      if (this.loggedIn && this.website) {
        this.formData.website = this.website;
      }

      if (this.showFileUpload) {
        this.$refs.imageUpload.validate();
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('----------------------------------');
        console.log('Submit', this);
        console.log('----------------------------------');
      }

      if (this.valid) {
        if (this.showFileUpload && !this.formData.files.length) return;

        Object.keys(this.formData).forEach(key => {
          if (this.formData[key] === null || this.formData[key] === undefined) {
            this.formData[key] = '';
          }
        });
        this.submit();
      }
    },

    resetForm() {
      const _default = defaultFormData();
      Object.keys(_default).forEach(k => {
        this.formData[k] = _default[k];
      });
      this.setUserData();
      this.setDefaultData();
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      } else {
        console.info('No form found');
      }
    },

    onCancel(emit = true) {
      this.resetForm();

      if (this.showFileUpload) {
        this.$refs.imageUpload.clearFiles();
      }

      if (emit) {
        this.$emit('cancel');
      }
    },
    clearAlert() {
      setTimeout(() => {
        this.success = null;
        this.error = null;
      }, 5000);
    },
    removeFile(file) {
      for (const i in this.formData.files) {
        if (this.formData.files[i].file === file.file) {
          this.formData.files.splice(i, 1);
          this.formData.images.splice(i, 1);
        }
      }
    },
    showField(field) {
      if (this.fields.length === 0) {
        return true;
      }
      return this.fields.includes(field);
    }
  }
};

const defaultFormData = () => {
  return {
    files: [],
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    caption: '',
    subCaption: '',
    forSale: false,
    hideName: false,
    public: true,
    private: false,
    sold: false,
    website: '',
    galleryId: 'public',
    parcelId: ''
  };
};
</script>

<style scoped lang="scss">
@media (max-width: 600px) {
  .feedback-button {
    margin-top: 2rem !important;
  }
}
</style>
