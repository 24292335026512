<template>
  <div v-if="detail">
    <v-dialog v-model="dialogs.members" max-width="600">
      <v-card class="mx-auto">
        <v-card-title>
          Select Members
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.members = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <SelectList
            :items="members"
            item-key="email"
            item-value="email"
            item-text="email"
            v-model="emails"
          >
            <template #footer="{ selected }">
              {{ selected }} member{{ selected > 1 ? 's' : '' }} selected
            </template>
          </SelectList>
          <v-btn large color="primary" @click="sendEmail(false)" class="mt-4 text-capitalize">
            Share Image <v-icon class="ml-2">mdi-email</v-icon>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.map" max-width="600">
      <UpdatePhotoStation
        :dialog.sync="dialogs.map"
        :geojson="geojson"
        :id="detail.id"
        @success="dialogs.map = false"
        :key="dialogs.map"
      />
    </v-dialog>
    <v-card color="img-card">
      <div v-if="detail">
        <div style="position: relative">
          <ImageViewer
            v-model="activeImageIndex"
            :images="detail.files"
            url-key="url"
          ></ImageViewer>
          <v-container class="card-container">
            <div class="">
              <div class="text-center">
                <div v-if="detail.caption" class="pb-2 mx-2 text-h5" v-html="detail.caption"></div>
                <div
                  v-if="detail.subCaption"
                  class="pb-2 light-black--text"
                  v-html="detail.subCaption"
                ></div>
                <div v-if="detail.artDescription" v-html="detail.artDescription"></div>
              </div>
              <div>
                <div class="d-flex">
                  <v-btn icon @click="sendEmail()" v-if="editable">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <CoordinatesAndOrientation
                    @click="permissionEditPhotoGps && geojson ? (dialogs.map = true) : {}"
                    :confirmed="permissionEditPhotoGps ? !!detail.geoDataConfirmed : true"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    :small="$vuetify.breakpoint.mdAndUp"
                    :coordinates="detail.geolocation"
                    :orientation="detail.orientation"
                  />
                  <v-spacer></v-spacer>
                  <div>
                    <div class="ml-3">
                      <v-menu
                        v-model="dialogs.menu"
                        :close-on-content-click="false"
                        transition="slide-y-transition"
                      >
                        <template #activator="{ on }">
                          <span class="info-btn" v-on="on">
                            <v-tooltip bottom color="primary">
                              <template #activator="{ on }">
                                <v-icon color="grey" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Image Information</span>
                            </v-tooltip>
                          </span>
                        </template>
                        <PostInfo
                          :editable="editable"
                          :geojson="geojson"
                          :galleryRef="galleryRef"
                          :loggedIn="true"
                          :gallery="detail"
                          :active-image="activeImageIndex"
                          @close="dialogs.menu = false"
                          @updated="onGalleryUpdate"
                          @delete="onGalleryDelete"
                        ></PostInfo>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-container>
        </div>
      </div>
      <div v-else>
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <div class="d-flex justify-center">
          <v-skeleton-loader type="chip" class="mt-3"></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center">
          <v-skeleton-loader type="text" width="250px" class="mt-3"></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center">
          <v-skeleton-loader type="text" width="150px" class="mt-3"></v-skeleton-loader>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import ImageViewer from './ImageViewer.vue';
import PostInfo from './PostInfo.vue';
import Organization from '../../services/organization';
export default {
  components: {
    ImageViewer,
    PostInfo,
    CoordinatesAndOrientation: () => import('../../components/CoordinatesAndOrientation.vue'),
    UpdatePhotoStation: () => import('../../components/Parcel/UpdatePhotoStation.vue'),
    SelectList: () => import('../../components/Core/SelectList.vue')
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    geojson: {},
    id: {
      type: String,
      default: ''
    },
    defaultData: {
      type: Object,
      default: null
    },
    activeImage: {
      type: Number,
      default: 0
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      }
    },
    galleryRef: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogs: {
        menu: false,
        map: false,
        members: false
      },
      commentForm: false,
      activeImageIndex: 0,
      detail: null,
      indexUpdated: false,
      galleryFeature: {
        like: false,
        comment: false
      },
      unsubscribe: null,
      members: [],
      emails: null
    };
  },
  watch: {
    id: {
      handler() {
        this.getData();
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchMembers();
  },
  computed: {
    permissionEditPhotoGps() {
      const editPhotoGps = this.$store.state.permissions.editPhotoGps;
      return editPhotoGps || this.$store.getters.isAdmin;
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    fetchMembers() {
      const { type, id } = this.$store.state.loggedInAs;
      if (type !== 'organization') {
        return;
      }
      const o = new Organization();
      o.getPermissions(id).then(org => {
        const m = org.data().members || [];
        const members = [];
        m.forEach(member => {
          members.push({ email: member.email, fullName: member.name });
        });
        this.members = members;
      });
    },
    sendEmail(showDialog = true) {
      const { type } = this.$store.state.loggedInAs;
      if (type === 'organization' && showDialog) {
        this.dialogs.members = true;
      } else {
        window.open(this.emailLink(), '_blank');
      }
    },
    emailLink() {
      const emails = (this.emails || []).join(',') || '';
      const image = this.detail.files[this.activeImage];
      const org = this.$store.state.loggedInAs;
      const name = org.type === 'organization' ? org.name : 'Peeps';
      let content = `mailto:${emails}?body=Here's a photo from the ${name} Gallery I wanted to share with you.
      \n\n${window.location.origin}/gallery#${image.id}`;
      return content;
    },
    async getData() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.defaultData) {
        this.detail = { ...this.defaultData, id: this.id };
      } else {
        this.unsubscribe = this.galleryRef.doc(this.id).onSnapshot(res => {
          if (res.exists) {
            this.detail = { ...res.data(), id: this.id };
          }
        });
      }
    },
    async onGalleryUpdate() {
      try {
        const data = await this.galleryRef.doc(this.id).get();
        if (data.exists) {
          this.detail = { ...data.data(), id: data.id };
        }
      } catch (error) {
        console.error(error);
      }
    },
    onGalleryDelete() {
      this.dialogs.menu = false;
      this.$emit('close');
    },
    getCategoryText(val) {
      const f = this.categories.filter(i => i.value === val);
      if (f[0]) {
        return f[0].abbr || f[0].text;
      }
      return this.capitalize(val);
    },
    capitalize(str) {
      return _.capitalize(str);
    }
  }
};
</script>

<style scoped lang="scss"></style>
