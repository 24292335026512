/* eslint-disable no-unused-vars */
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(FilePondPluginFileValidateSize);
FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginFileValidateType);

const createFilepond = ({ el, onProcess, instantUpload, allowRevert, ...args }) => {
  const inputElement = document.querySelector(el);
  if (instantUpload === undefined) {
    instantUpload = true;
  }
  if (allowRevert == undefined) {
    allowRevert = false;
  }
  return FilePond.create(inputElement, {
    allowFileSizeValidation: true,
    maxFileSize: '100MB',
    allowFileTypeValidation: true,
    acceptedFileTypes: [],
    allowRevert,
    labelIdle: `
        Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span>`,

    instantUpload,
    server: {
      process: async (fieldName, file, metadata, load, error) => {
        try {
          await onProcess({ file, metadata });
          load();
        } catch (err) {
          error();
          throw err;
        }
      }
    },
    ...args
  });
};

export default createFilepond;
