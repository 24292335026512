import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap justify-center"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"img-thumbnail d-flex",on:{"mouseenter":_vm.onMouseEnter}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"base-image-container"},[_c('base-image',{staticClass:"base-image cursor-pointer",attrs:{"cover":"","max-width":"200","src":item.thumbnail || item.files[0].url},on:{"click":function($event){return _vm.$emit('showDetail', item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.private),expression:"item.private"}],staticClass:"label-private opacity-75"},[_c(VIcon,{attrs:{"color":"white","size":"13"}},[_vm._v("mdi-lock")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.files.length > 1),expression:"item.files.length > 1"}],staticClass:"images-count-container opacity-75"},[_c('span',{staticClass:"images-count cursor-pointer"},[_c(VIcon,{attrs:{"size":"13","color":"white"}},[_vm._v("mdi-image-multiple")])],1)]),_c(VFadeTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDateShared),expression:"showDateShared"}],staticClass:"date-shared opacity-75"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt.toDate(), 'YYYY-MM-DD'))+" ")])]),_c(VSlideXTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showViews && item.views),expression:"showViews && item.views"}],staticClass:"views-count-container opacity-75"},[_c('span',{staticClass:"views-count"},[_vm._v(" "+_vm._s(item.views)+" ")])])])],1),(hover)?_c(VFadeTransition,[_c('div',{staticClass:"image-detail"},[_c('gallery-detail-hover',{attrs:{"galleryCommentRef":_vm.galleryCommentRef,"galleryRef":_vm.galleryRef,"show-date-shared":!_vm.showDateShared,"id":item.id}})],1)]):_vm._e()],1)]}}],null,true)})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }