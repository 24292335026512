<template>
  <div>
    <BaseSwiper
      ref="swiper"
      :key="images[0][urlKey]"
      v-model="active"
      :navigation="images.length > 1"
      :pagination="images.length > 1"
    >
      <base-swiper-slide v-for="image in images" :key="JSON.stringify(image)">
        <v-img :src="imageURL(image)" max-height="80vh"></v-img>
      </base-swiper-slide>
    </BaseSwiper>
    <slot :active="active" :activeImage="images[active]"></slot>
  </div>
</template>

<script>
export default {
  components: {
    BaseSwiper: () => import('../../components/Core/Swiper.vue')
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      default: 0
    },
    urlKey: {
      type: String,
      default: 'url'
    }
  },
  data() {
    return {
      active: 0
    };
  },
  watch: {
    value: {
      handler: 'setActiveIndex',
      immediate: true
    },
    active() {
      this.$emit('input', this.active);
    }
  },
  methods: {
    imageURL(img) {
      // if (this.extension(img.name) === 'heic') {
      //   return img['800']
      // }
      return img[this.urlKey];
    },
    setActiveIndex() {
      if (this.active !== this.value) {
        this.active = this.value;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$action-button-size: 30px;
.card {
  position: relative;
}
.action-next {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 5px;
  width: $action-button-size !important;
  height: $action-button-size !important;
  font-size: $action-button-size !important;
}
.action-prev {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: 1rem;
  width: $action-button-size !important;
  height: $action-button-size !important;
  font-size: $action-button-size !important;
}
.pagination-container {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
@media (max-width: 600px) {
  .action-prev {
    display: none;
  }
  .action-next {
    display: none;
  }
}
</style>
