<template>
  <div>
    <div>
      <input id="file" type="file" :multiple="multiple" />
    </div>
    <div>
      <div class="text-left">
        <div>
          <div v-show="converting" class="convert-progress my-2">
            <span>Converting images</span>
            <v-progress-linear indeterminate></v-progress-linear>
          </div>
          <div class="errors">
            <div v-for="key in Object.keys(errorBag)" :key="key" class="error-container">
              <v-alert v-if="!errorBag[key].valid" type="error" text>{{
                errorBag[key].detail
              }}</v-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueCompositionAPI, {
  defineComponent,
  reactive,
  computed,
  onMounted,
  toRefs,
  ref,
  watch
} from '@vue/composition-api';

Vue.use(VueCompositionAPI);

import EXIF from 'exif-js';
import { uploadFile, STORAGE, getDownloadURL } from '@/services/storage';
import { createFilePond } from '../../utils/filepond';
import { extension, updateExtension, getFilePath, indexOf, updateObject } from '@/utils/file';

export default defineComponent({
  name: 'ImageUpload',

  props: {
    required: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    let pond;

    const HEIC_MAX_RETRY = 10;
    const HEIC_RETRY_TIMEOUT = 5000;

    const files = ref([]);
    const uploading = ref(false);

    const valid = ref(true);

    const data = reactive({
      errorBag: { required: { valid: true, detail: '' } },
      resetForm: false
    });

    const ids = computed(() => {
      return files.value.map(e => e.id);
    });

    const converting = computed(() => {
      const value = files.value.some(e => extension(e.name) === 'heic' && !e.convertError);
      return value;
    });

    watch(
      uploading,
      value => {
        context.emit('update:uploading', value);
      },
      { immediate: true }
    );

    watch(
      files,
      val => {
        context.emit('update:files', val);
      },
      { immediate: true }
    );
    watch(
      ids,
      val => {
        context.emit('update:ids', val);
      },
      { immediate: true }
    );
    watch(
      valid,
      val => {
        context.emit('input', val);
      },
      { immediate: true }
    );
    watch(
      converting,
      val => {
        context.emit('update:converting', val);
      },
      { immediate: true }
    );

    const convertHEIC = async (id, retry = 0) => {
      if (retry > HEIC_MAX_RETRY) {
        console.warn('EXITING', retry);
        const index = indexOf(files.value, id);
        files.value[index].convertError = true;
        return;
      }

      const file = files.value.filter(file => file.id === id)[0];
      if (!file) {
        return;
      }

      const newFileName = updateExtension(file.name, 'jpeg');
      const convertedPath = getFilePath(['gallery'], newFileName);
      let url;

      try {
        url = await getDownloadURL(convertedPath);

        const index = indexOf(files.value, id);
        if (index === -1) {
          throw new Error('IndexNotFound: Error updating converted image data');
        }

        const newData = { name: newFileName, url, path: convertedPath };
        files.value[index] = updateObject(files.value[index], newData);

        return;
      } catch (error) {
        console.warn(error);
      }

      setTimeout(() => {
        convertHEIC(id, retry + 1);
      }, HEIC_RETRY_TIMEOUT);
    };

    /* ----------- Validation -----------  */
    const resetValidation = () => {
      valid.value = true;
      Object.keys(data.errorBag).forEach(key => {
        data.errorBag[key].valid = true;
      });

      context.emit('input', valid.value);
    };

    const validate = () => {
      resetValidation();

      if (props.required) {
        if (!files.value.length) {
          data.errorBag.required = reactive({
            valid: false,
            detail: 'At least one image is required'
          });
          valid.value = false;
        }
      }

      context.emit('input', valid.value);
    };

    /* ----------- Image Processing -----------  */
    // uplaod image to cloud storage
    const getLocation = url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          EXIF.getData(img, function () {
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lat = EXIF.getTag(this, 'GPSLatitude');
            if (!lon) {
              return reject(new Error('Image does not have GPS'));
            }
            if (!lat) {
              return reject(new Error('Image does not have GPS'));
            }

            const longitude = Number(lon[0]) + Number(lon[1]) / 60 + Number(lon[2]) / 3600;
            const latitude = Number(lat[0]) + Number(lat[1]) / 60 + Number(lat[2]) / 3600;
            resolve({ latitude, longitude });
          });
        };
        img.onerror = reject;
        img.src = url;
      });
    };
    const onProcessFile = async file => {
      context.emit('process');
      const id = file.id;
      const path = `/${STORAGE.gallery}/${id}_${file.name}`;
      await uploadFile(file, path);
      const url = await getDownloadURL(path);

      const fileInfo = {
        name: `${id}_${file.name}`,
        path,
        convertError: false,
        url,
        id,
        size: file.size
      };

      const u = URL.createObjectURL(file);
      try {
        const gps = await getLocation(u);
        fileInfo.location = gps;
      } catch (error) {
        console.warn(error);
      }
      files.value.push(fileInfo);

      data.errorBag.required.valid = true;
      context.emit('input', true);

      if (extension(file.name) === 'heic') {
        convertHEIC(id);
      }
    };
    // remove image
    const onRemoveFile = id => {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        files.value.forEach((item, index) => {
          if (item.id === id) {
            files.value.splice(index, 1);
            resolve();
          }
        });
        if (!data.resetForm) {
          validate();
        }
      });
    };
    const clearFiles = async () => {
      data.resetForm = true;
      await pond.removeFiles();
      setTimeout(() => {
        data.resetForm = false;
      }, 3000);
      resetValidation();
    };

    const updateLoading = () => {
      uploading.value = pond.getFiles().filter(x => x.status !== 5).length !== 0;
    };

    onMounted(() => {
      pond = createFilePond(
        '#file',
        onProcessFile,
        onRemoveFile,
        {
          captureMethod: props.mode || null,
          label: props.mode === 'camera' ? 'Click to take Photo' : null
        },
        { updateLoading }
      );
    });

    return {
      validate,
      resetValidation,
      clearFiles,
      converting,
      files,
      valid,
      ...toRefs(data)
    };
  }
});
</script>

<style>
.filepond--credits {
  display: none !important;
}
</style>
