import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" New Folder "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('CreateFolderForm',{attrs:{"loading":_vm.loading},on:{"submit":_vm.onCreateFolder,"cancel":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"messages",fn:function(){return [(_vm.messages.createFolder.error)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.messages.createFolder.error)+" ")]):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }