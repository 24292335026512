<template>
  <v-btn
    :color="color(true)"
    text
    v-bind="$attrs"
    @click="$emit('click')"
    class="cursor-pointer v-btn--active"
    style="text-transform: none"
    ><slot>
      <span :style="`color: ${color()}`">
        {{ landTypeText(type) }}
      </span>
      <v-icon :small="$attrs['x-small']" :color="color()" class="ml-1"
        >mdi-folder-information-outline</v-icon
      ></slot
    ></v-btn
  >
</template>

<script>
import Land from '../../../services/land';
const land = new Land();
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    color(light) {
      const feature = this.$store.getters.featuresList.filter(ft => ft.feature === 'land')[0];
      if (light) {
        return feature.style.colorLight;
      }
      return feature.style.color;
    },
    landTypeText(value) {
      const res = land.LAND_TYPES.filter(type => type.value === value);
      if (res.length) {
        return res[0].shortName;
      } else {
        return value;
      }
    }
  }
};
</script>

<style></style>
