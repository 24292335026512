import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Edit Parcel "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('base-parcel-form',{attrs:{"showOpt":true,"clearForm":false,"data":_vm.parcelData},on:{"submit":_vm.update,"cancel":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("Update "),_c(VIcon,[_vm._v("mdi-content-save")])]},proxy:true}])})],1)],1)],1),_c(VBtn,_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',_vm.$attrs,false),[_vm._v(" Edit "),_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }