<script>
import { useDocumentHistory } from '../../services/documentHistory';
import Orientation from '../../services/orientation';
import BaseForm from './GalleryBaseForm.vue';
const {addDocHistory} = useDocumentHistory()
export default {
  extends: BaseForm,
  props: {
    data: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: false,
      default: null
    },
    update: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Update'
    }
  },
  watch: {
    data: {
      handler(value) {
        const orientation = new Orientation();
        this.bearing = orientation.compassPoint(value?.orientation?.heading);
      },
      immediate: true
    }
  },
  methods: {
    // updateGpsOrientation() {
    //   try {
    //     const orientation = new Orientation();
    //     this.bearing = orientation.compassPoint(value?.orientation?.heading);
    //     this.updateFormData.orientation = value.orientation;
    //     this.updateFormData.bearing = orientation.compassPoint(value?.orientation?.heading);
    //   } catch {}
    //   try {
    //     this.updateFormData.geolocation = value.geolocation;
    //   } catch {}
    // },
    parseTimestamp(ts) {
      let date = null;
      if (ts && typeof ts.getMonth === 'function') {
        date = new Date(ts);
      } else if (typeof ts === 'object') {
        date = new Date(ts.seconds * 1000);
      } else {
        date = new Date(ts);
      }
      if (date === 'Invalid Date') {
        return '';
      }
      return date;
    },
    async submit() {
      this.loading = true;

      let type;
      if (typeof this.formData.type === 'object') {
        type = this.formData.type.value;
      } else {
        type = this.formData.type;
      }

      this.formData.geolocation = this.updateFormData.geolocation || this.formData.geolocation;
      this.formData.orientation = this.updateFormData.orientation || this.formData.orientation;

      const data = {
        ...this.formData,
        type,
        updatedAt: new Date(),
        createdAt: this.parseTimestamp(this.formData.createdAt)
      };
      const id = data.id;
      delete data.id;

      if (data.files && data.files.length) {
        data.files.map(e => {
          delete e.file;
          return e;
        });
      }

      try {
        await this.galleryRef.doc(id).update(data).then(()=>{
          addDocHistory({
            id,
            data,
            docCollection: this.galleryRef?.id
          })
        })
        this.success = 'Gallery updated successfully';
        this.clearAlert();
        this.$emit('success');
      } catch (err) {
        console.error(err);
        this.error = 'Oops! Something went wrong!';
        this.clearAlert();
        this.$emit('error', err);
      }

      this.loading = false;
      this.$emit('submit', this);
    },
    onGallerySold(val) {
      if (val) {
        if (!this.data) {
          return;
        }
        if (this.data && !this.data.sold) {
          this.formData.soldAt = new Date();
        }
      }
    }
  }
};
</script>

<style></style>
