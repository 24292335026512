<template>
  <v-btn fab small class="action-close mt-2 mr-2" color="white" @click="$emit('input', false)">
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.action-close {
  position: absolute;
  top: 0;
  right: 0;
  // margin-top: 5px;
  // margin-right: 5px;
}
</style>
