import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('hvac-chip',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"min-width":"400","max-width":"600"}},[_c(VCardTitle,[_vm._v(" Energy Source information "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary","to":("/parcels/" + _vm.parcelId + "/hvac?info=true")}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{staticClass:"system-information"},[_c('p',[_c('fuels-chip',{attrs:{"data":_vm.information}})],1),(_vm.information.oil.supplier)?_c('p',{staticClass:"mt-3"},[_vm._v(" Oil Supplier: "+_vm._s(_vm.information.oil.supplier)+" ")]):_vm._e(),(_vm.information.propane.supplier)?_c('p',{staticClass:"mt-3"},[_vm._v(" Propane Supplier: "+_vm._s(_vm.information.propane.supplier)+" ")]):_vm._e(),(_vm.information.note)?_c('p',[_c('span',[_vm._v("Notes:")]),_c('br'),_c('span',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.information.note)+" ")])]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }