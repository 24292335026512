const Country = {
    name: "",
    code: "",
    iso: ""
}

const country = {
    us: {
        name: "United States",
        code: '+1',
        iso: 'US'
    },

    /**
     * 
     * @param {string} code 
     * @returns {Country}
     */

    get(code) {
        return this[code] || this.us
    }
}
const args = {
    phone: '',
    code: ''
}
export default class Phone {
    /**
     * 
     * @param {args} args
     */
    constructor(args) {
        this.set(args)
    }

    /**
     * 
     * @param {args} args
     */
    set({ phone, code } = {}) {
        this.phone = (phone || '').replace(/\D/g, "")
        this.iso = country.get(code).iso
        this.code = country.get(code).code
    }

    format() {
        let phone = this.phone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        let res = '';
        if (phone[1]) {
            res += `(${phone[1]}`
            if (phone[1].length === 3) {
                res += ')'
            }
        }
        if (phone[2]) {
            res += ` ${phone[2]}`
            if (phone[2].length === 3) {
                res += '-'
            }
        }
        if (phone[3]) {
            res += `${phone[3]}`
        }
        return res;
    }
}