import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle),_c(VCardText,[_vm._v(" Are you sure? Do you want to delete this parcel? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","loading":_vm.$store.getters.parcelLoading},on:{"click":_vm.deleteCurrentParcel}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("No")]),_c(VSpacer)],1)],1)],1),_c(VBtn,_vm._b({staticClass:"text-capitalize",attrs:{"icon":_vm.buttonIcon,"block":"","color":"error"},on:{"click":function($event){_vm.dialog = true}}},'v-btn',_vm.$attrs,false),[_vm._t("text",[(!_vm.buttonIcon)?_c('span',[_vm._v(" Delete ")]):_vm._e(),_c(VIcon,[_vm._v("mdi-trash-can")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }