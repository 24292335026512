<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      right
      transition="scale-transition"
      origin="top left"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <hvac-chip v-model="value"></hvac-chip>
        </span>
      </template>
      <v-card min-width="400" max-width="600">
        <v-card-title>
          Energy Source information
          <v-spacer></v-spacer>
          <v-btn icon color="primary" :to="`/parcels/${parcelId}/hvac?info=true`"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click="menu = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container class="system-information">
          <p>
            <fuels-chip :data="information"></fuels-chip>
          </p>
          <p v-if="information.oil.supplier" class="mt-3">
            Oil Supplier: {{ information.oil.supplier }}
          </p>
          <p v-if="information.propane.supplier" class="mt-3">
            Propane Supplier: {{ information.propane.supplier }}
          </p>
          <p v-if="information.note">
            <span>Notes:</span><br />
            <span class="ml-5">
              {{ information.note }}
            </span>
          </p>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['value', 'parcelId'],
  data: () => ({
    menu: false
  }),
  components: {
    HvacChip: () => import('@/components/Core/Chip/HVACChip.vue'),
    FuelsChip: () => import('@/components/Core/Chip/FuelsChip.vue')
  },
  computed: {
    information() {
      return this.value;
    }
  }
};
</script>

<style scoped>
.system-information {
  font-size: 1rem;
}
p {
  margin-top: -0.4rem;
}
</style>
