<template>
  <div v-if="data">
    <v-dialog v-model="dialogs.email" max-width="400">
      <GrantorEmail
        :key="dialogs.email"
        @success="dialogs.email = false"
        v-model="dialogs.email"
        :parcel-id="parcelId"
      />
    </v-dialog>
    <v-menu
      v-model="menu"
      top
      right
      transition="scale-transition"
      origin="top left"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <LandChip :type="data.type" x-small />
        </span>
      </template>
      <v-card max-width="600">
        <v-card-title>
          Land Information
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :to="`/parcels/${parcelId}/land?information=true`"
            v-if="permissions.create || $store.getters.isAdmin"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click="menu = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container class="system-information">
          <p>
            <land-chip :type="data.type" />
            <v-chip color="info" v-if="data.status" class="ml-1" small>
              {{ data.status }}
            </v-chip>
            <v-chip color="info" v-if="data.trails" class="ml-1" small> Trails </v-chip>
          </p>
          <div v-for="field in fields" :key="field.value" class="mb-1">
            <span v-if="data[field.value]">
              {{ field.text }}:
              <v-chip
                class="text-capitalize"
                v-if="field.type === 'chip'"
                :color="field.color || 'primary'"
                v-bind="field.attrs"
              >
                {{ data[field.value] }}
              </v-chip>
              <span v-else>
                <span v-if="field.fmt">
                  {{ field.fmt(data[field.value]) }}
                </span>
                <span v-else>
                  {{ data[field.value] }}
                </span>
              </span>
              <div
                v-if="
                  field.value === 'grantor' &&
                  contact &&
                  (contact.name ||
                    contact.email ||
                    (contact.phone && contact.phone.length && contact.phone[0].number)) &&
                  data.grantor !== contact.name
                "
                class="primary--text"
              >
                <v-divider class="my-1"></v-divider>
                <strong> Contact </strong>
                <div>
                  <div v-if="contact.firstName || contact.lastName">
                    {{ contact.firstName + ' ' + contact.lastName }}
                  </div>
                  <div
                    v-if="contact.email"
                    class="primary--text my-2 cursor-pointer"
                    @click="dialogs.email = true"
                  >
                    <v-btn color="primary" icon>
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                    {{ contact.email }}
                  </div>
                  <div v-if="contact.phone && contact.phone.length">
                    <div v-for="phone in contact.phone" :key="phone.number" class="my-2">
                      <CallMessage :number="phone.number" :type="phone.label" v-if="phone.number" />
                    </div>
                  </div>
                  <v-divider class="my-1"></v-divider>
                </div>
              </div>
            </span>
          </div>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import LandChip from '../../components/Core/Chip/LandChip.vue';
import _ from 'lodash';
import { mapState } from 'vuex';
import Contact from '../../services/contact';
export default {
  props: ['data', 'parcelId'],
  data: () => ({
    menu: false,
    dialogs: {
      email: false
    },
    fields: [
      {
        text: 'Binder Status',
        value: 'binder',
        type: 'chip',
        attrs: { small: true, outlined: true }
      },
      { text: 'Binder Notes', value: 'binderNotes' },
      { text: 'Date Acquired', value: 'dateAcquired' },
      { text: 'Grantor', value: 'grantor' },
      { text: 'Acres Upland', value: 'acresUpland' },
      { text: 'Acres Wetland', value: 'acresWetland' },
      { text: 'Primary Habitats', value: 'primaryHabitats' },
      { text: 'Comment', value: 'comment' },
      { text: 'Preserve', value: 'preserve', fmt: _.startCase },
      { text: 'CR Reference', value: 'crReference' },
      { text: 'CR Purpose', value: 'crPurpose' },
      { text: 'Trust Deed', value: 'trustDeed' },
      { text: 'Orig Deed', value: 'origDeed' },
      { text: 'Plan Bk/Pg; Lot', value: 'plan' },
      { text: 'Acquistion Value', value: 'acquistionValue' }
    ],
    contact: null
  }),
  components: {
    LandChip,
    CallMessage: () => import('../CallMessage.vue'),
    GrantorEmail: () => import('../../components/Land/GrantorEmail.vue')
  },
  mounted() {
    const c = new Contact();
    c.get(this.parcelId).then(data => {
      if (data?.data) {
        this.contact = data.data;
      }
    });
  },
  computed: {
    ...mapState(['permissions'])
  },
  methods: {
    phoneIcon(label) {
      return Contact.phoneIcon(label);
    }
  }
};
</script>

<style scoped>
.system-information {
  font-size: 1rem;
}
p {
  margin-top: -0.4rem;
}
</style>
