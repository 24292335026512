<template>
  <div>
    <v-dialog v-model="dialogs.detail" max-width="600" max-height="80vh">
      <div v-if="activePost" class="position: relative">
        <v-btn
          @click="dialogs.detail = false"
          fab
          x-small
          style="position: absolute; top: 0; right: 0; z-index: 5"
          class="mr-1 mt-1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="d-flex" style="position: absolute; top: 0; z-index: 2; width: 100%">
          <v-spacer></v-spacer>
          <div>
            <v-btn :disabled="!hasPrev" @click="prev()" text dark x-small class="mr-1 v-btn--active"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn :disabled="!hasNext" @click="next()" text dark x-small class="v-btn--active"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <v-spacer></v-spacer>
        </div>
        <PostDetail
          :geojson="geojson"
          :galleryRef="galleryRef"
          :id="activePost.id"
          :key="activePost.id"
          @close="dialogs.detail = false"
        />
      </div>
    </v-dialog>

    <div class="alerts">
      <v-fade-transition>
        <v-alert type="info" v-show="info" text>{{ info }}</v-alert>
      </v-fade-transition>
      <v-fade-transition>
        <v-alert type="error" v-show="error" text>{{ error }}</v-alert>
      </v-fade-transition>
    </div>
    <div>
      <v-alert type="info" v-if="!postsComputed.length && posts.length" text>
        No images were found matching your filter
        <br />
        <span>
          <strong>
            {{ posts.length }}
          </strong>
          image{{ posts.length > 1 ? 's are' : ' is ' }} stored - most recent is
          <strong>
            {{ recentPostDate() }}
          </strong>
        </span>
      </v-alert>
      <PostsGroup
        :items="postsComputed"
        :galleryRef="galleryRef"
        :galleryCommentRef="galleryCommentRef"
        :show-date-shared="showDate"
        @showDetail="showDetail"
      />
    </div>
  </div>
</template>

<script>
import PostsGroup from './PostGroup.vue';
import PostDetail from './PostDetail.vue';
import GalleryButtonClose from './ButtonClose.vue';
import { galleryCommentRef } from '@/services/firestore';
import config from '../../config';
import moment from 'moment';
export default {
  components: {
    PostsGroup,
    PostDetail
    // GalleryButtonClose
  },
  props: {
    parcel: { type: String, required: true },
    geojson: {},
    galleryRef: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    showDate: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      dfeault: null
    }
  },
  data() {
    return {
      dialogs: { detail: false },
      galleryCommentRef,
      posts: [],
      loading: true,
      error: '',
      info: '',
      activePost: null,
      unsubscribe: null
    };
  },
  computed: {
    hasNext() {
      const index = this.posts.findIndex(i => i.id === this.activePost?.id);
      return index < this.posts.length - 1;
    },
    hasPrev() {
      const index = this.posts.findIndex(i => i.id === this.activePost?.id);
      return index > 0;
    },
    postsComputed() {
      let res = [...(this.posts || [])];
      if (this.filter.from && this.filter.to) {
        res = res.filter(item => {
          try {
            const date = moment(item.imageDate || item.createdAt.toDate());
            const from = moment(this.filter.from);
            const to = moment(this.filter.to);
            const filter = date.isSameOrAfter(from, 'days') && date.isSameOrBefore(to, 'days');
            return filter;
          } catch (err) {
            console.log(err);
            return true;
          }
        });
      }
      this.$emit('update:images', res);
      this.$emit('update:count', res.length);
      return res;
    }
  },
  beforeMount() {
    this.getPosts();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    recentPostDate() {
      try {
        return moment(this.posts[0].createdAt.toDate()).format('YYYY-MM-DD');
      } catch {
        return '';
      }
    },
    next() {
      const index = this.posts.findIndex(i => i.id === this.activePost?.id);
      this.activePost = this.posts[index + 1];
    },
    prev() {
      const index = this.posts.findIndex(i => i.id === this.activePost?.id);
      this.activePost = this.posts[index - 1];
    },
    showDetail(obj) {
      this.activePost = obj;
      this.dialogs.detail = true;
    },
    async getPosts() {
      this.loading = true;
      try {
        this.unsubscribe = this.galleryRef
          .where('galleryId', '==', config.galleryID())
          .where('parcelId', '==', this.parcel)
          .where('type', '==', this.category)
          .onSnapshot(
            res => {
              this.error = '';
              this.info = '';
              const _posts = [];
              res.forEach(i => {
                _posts.push({ ...i.data(), id: i.id });
              });
              this.posts = _posts;
              this.$emit('update:allImages', this.posts);
              if (this.posts.length === 0) {
                this.info = 'No images found';
              }
            },
            error => {
              this.error = error.message;
            }
          );
      } catch (error) {
        console.error(error);
        this.error = error.message;
      }
      this.loading = false;
    }
  }
};
</script>

<style>
</style>
