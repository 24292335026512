import { randomString } from '../utils/random';
import { firestore, COLLECTION } from './firestore';

class Gallery {
  constructor({ email } = {}) {
    this.email = email;
    this.ref = firestore.collection('news_galleries');
  }
  static async getImageId() {
    let isUnique = false;
    let id;
    let count = 0;
    while (!isUnique && count < 100) {
      count++;
      id = randomString(6);
      const check = await firestore
        .collection(COLLECTION.gallery)
        .where('images', 'array-contains', id)
        .get();
      if (!check.docs.length) {
        isUnique = true;
      }
    }
    return id;
  }

  list({ organization } = {}) {
    return new Promise((resolve, reject) => {
      let q = this.ref;
      if (organization) {
        q = q.where('organization', '==', organization);
      }
      q.get()
        .then(res => {
          const data = [];
          res.forEach(doc => {
            data.push({ id: doc.id, data: doc.data() });
          });
          resolve(data);
        })
        .catch(error => reject(error));
    });
  }

  update(id, newData) {
    return new Promise((resolve, reject) => {
      this.detail(id).then(({ data }) => {
        const galleryData = {
          ...data, ...(newData || {}),
          updatedAt: new Date(),
        }
        this.ref.doc(id).set(galleryData)
        resolve(galleryData)
      }).catch(err => {
        reject(err)
      })
    })
  }

  detail(id) {
    return new Promise((resolve, reject) => {
      this.ref.doc(id).get().then(doc => {
        if (doc.exists) {
          resolve({ id, data: doc.data() })
        }
        else {
          reject(new Error("gallery does not exists"))
        }
      }).catch(err => {
        reject(err)
      })
    })
  }

  get({ imageId } = {}) {
    return new Promise((resolve, reject) => {
      this.ref.where("images", "array-contains", imageId).get().then(res => {
        if (res.size) {
          resolve({ id: res.docs[0].id, data: res.docs[0].data() })
        }
        else {
          reject(new Error("Gallery not found"))
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default Gallery;
