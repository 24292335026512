<template>
  <div>
    <v-dialog v-model="dialogs.moveTo" width="600">
      <v-card>
        <v-card-title>
          Move to
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.moveTo = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <DriveMoveTo
            :key="dialogs.moveTo"
            :file-id="moveId"
            :folder-id="folderId"
            @success="onSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="800" v-model="dialogs.preview">
      <v-card class="mx-auto">
        <v-card-title style="position: sticky; top: 0; z-index: 5">
          <v-spacer></v-spacer>
          <v-btn class="v-btn--active" icon @click="dialogs.preview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <pdf-viewer :key="pdf.url" :url="pdf.url" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" left :color="snackbar.type">
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogs.createFolder" width="600">
      <CreateFolder
        :other-data="otherData"
        :key="dialogs.createFolder"
        :user="user || ''"
        :parent-id="folder || folderId"
        @success="onCreateFolder"
        @close="dialogs.createFolder = false"
        @cancel="dialogs.createFolder = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogs.uploadFile" width="600" persistent>
      <UploadFile
        :other-data="otherData"
        :key="dialogs.uploadFile"
        v-model="dialogs.uploadFile"
        :user="user || ''"
        :parent-id="folder || folderId"
        @close="dialogs.uploadFile = false"
        @upload="onUploadFile"
      />
    </v-dialog>
    <v-dialog v-model="dialogs.delete" width="400">
      <Confirmation
        v-model="dialogs.delete"
        @confirm="confirmDelete"
        @cancel="dialogs.delete = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogs.rename" width="400">
      <v-card class="mx-auto">
        <v-card-title>
          Rename <v-spacer />
          <v-btn icon>
            <v-icon @click="dialogs.rename = false"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <RenameFolder
            :other-data="otherData"
            :id="objectID"
            :key="dialogs.rename"
            v-model="dialogs.rename"
            :type="objectType"
            @success="reloadTree()"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <v-treeview
      :items="treeDataComputed"
      item-key="id"
      item-text="name"
      :open="open"
      @update:open="onOpenUpdate"
    >
      <template #label="{ item }">
        <div>
          <div>
            <span v-if="item.description">
              <base-tooltip bottom color="primary">
                <template #activator="{ on }">
                  <span
                    :class="$vuetify.breakpoint.name === 'xs' ? 'primary--text' : ''"
                    @click="preview(item)"
                    v-on="on"
                  >
                    {{ formatDescription(item) }}
                  </span>
                </template>
                <span>{{ item.name }}</span>
              </base-tooltip>
            </span>
            <span
              v-else
              :class="
                item.type === 'file'
                  ? $vuetify.breakpoint.name === 'xs'
                    ? 'primary--text'
                    : ''
                  : ''
              "
              @click="preview(item)"
            >
              {{ item.name }}
              <!-- <span
                v-if="
                  item.type === 'file' &&
                    $store.state.drive.config.showFilesize &&
                    showFileInfo
                "
              > -->
              <span v-if="item.type === 'file' && showFilesize && showFileInfo">
                [{{ getSize(item.size) }}]
              </span>
            </span>
            <v-icon
              v-if="downloadIconWithLabel && item.type === 'file'"
              color="primary"
              @click="download(item)"
            >
              mdi-download
            </v-icon>
          </div>
        </div>
      </template>
      <template #prepend="{ item }">
        <v-icon
          :color="$vuetify.breakpoint.name === 'xs' && item.type !== 'folder' ? 'primary' : ''"
          @click="onClickPrependIcon(item)"
        >
          {{ getIcon(item) }}
        </v-icon>
      </template>
      <template #append="{ item }">
        <div>
          <v-icon v-if="item.type === 'file' && isPdf(item)" color="primary" @click="preview(item)">
            mdi-file-find
          </v-icon>
          <v-icon
            v-if="item.type === 'folder' && !hideCreateFolder"
            color="primary"
            @click="showCreateFolder(item.id)"
          >
            mdi-folder-plus-outline
          </v-icon>
          <v-icon
            v-if="item.type === 'folder' && !hideUploadFile"
            color="info"
            @click="showUploadFile(item.id)"
          >
            mdi-upload
          </v-icon>
          <v-icon
            v-if="item.type === 'file' && !downloadIconWithLabel"
            class="d-none d-sm-inline"
            color="primary"
            @click="download(item)"
          >
            mdi-download
          </v-icon>
          <v-menu :close-on-content-click="false" bottom offset-y>
            <template #activator="{ on }">
              <v-icon v-if="!hideSetting" v-on="on"> mdi-cog </v-icon>
            </template>
            <template #default>
              <v-card width="240" class="mx-auto">
                <v-container>
                  <div v-if="!hideCopyId" class="text-center">
                    <CopyText
                      :text="item.id"
                      btn-text="Copy ID"
                      :btn-attrs="btnAttrs"
                      success-message="ID Copied to clipboard"
                      @copy="showSnackbar"
                    >
                      <template #text="{ text }"> ID: {{ text }} </template>
                    </CopyText>
                  </div>
                  <div class="text-center">
                    <CopyText
                      v-if="item.type === 'file'"
                      @copy="showSnackbar"
                      color="success"
                      :text="item.url"
                      success-message="File link copied"
                      class="mt-2"
                      icon="mdi-share"
                      btn-text="Copy File Link"
                      :btn-attrs="btnAttrs"
                    >
                      <template #text>
                        <span />
                      </template>
                    </CopyText>
                    <v-btn
                      block
                      class="mt-2 text-capitalize"
                      outlined
                      text
                      color="info"
                      @click="showRename(item.id, item.type)"
                    >
                      Rename <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      block
                      v-if="$store.getters.isAdmin"
                      class="mt-2 text-capitalize"
                      text
                      outlined
                      color="error"
                      @click="showDeleteDialog(item)"
                    >
                      Delete
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="item.type === 'file' && $store.getters.isAdmin"
                      color="indigo"
                      block
                      class="mt-2 text-capitalize"
                      outlined
                      @click="showMoveTo(item)"
                      >Move to <v-icon> mdi-file-move</v-icon></v-btn
                    >
                  </div>
                </v-container>
              </v-card>
            </template>
          </v-menu>
        </div>
      </template>
    </v-treeview>
    <v-alert type="info" v-if="!treeDataComputed.length && !loading"
      >No files and folders found</v-alert
    >

    <v-progress-linear v-if="loading" indeterminate />

    <div class="mt-3" :class="{ 'text-center': centerButtons }">
      <v-btn
        v-if="!hideCreateFolder"
        color="primary"
        outlined
        class="text-capitalize mr-2"
        @click="dialogs.createFolder = true"
        :small="smallButtons"
      >
        New Folder <v-icon>mdi-folder-plus-outline</v-icon>
      </v-btn>
      <v-btn
        :small="smallButtons"
        v-if="!hideUploadFile"
        color="primary"
        outlined
        class="text-capitalize"
        @click="dialogs.uploadFile = true"
      >
        Upload File <v-icon>mdi-upload</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import filesize from 'filesize';
import CreateFolder from './CreateFolder.vue';
import UploadFile from './UploadFile.vue';
import Confirmation from './Confirmation.vue';
import RenameFolder from './Rename.vue';
import CopyText from './CopyText.vue';

import Drive from './drive';

import { getExtension, addExtension, downloadURI } from './utils';
import EventBus from './event-bus';
import Parcel from '../../services/parcel';
import { sort } from '../../utils';

const drive = new Drive();
export default {
  name: 'Drive',

  components: {
    Confirmation,
    CreateFolder,
    UploadFile,
    CopyText,
    RenameFolder,
    PdfViewer: () => import('../PdfViewer.vue'),
    DriveMoveTo: () => import('./MoveTo.vue')
  },

  props: {
    otherData: {
    },
    hideCopyId: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: String,
      default: null
    },
    showFileInfo: {
      type: Boolean,
      default: false
    },
    hideCreateFolder: {
      type: Boolean,
      default: false
    },
    hideUploadFile: {
      type: Boolean,
      default: false
    },
    hideSetting: {
      type: Boolean,
      default: false
    },
    downloadIconWithLabel: {
      type: Boolean,
      default: false
    },
    showFilesize: {
      type: Boolean,
      default: false
    },
    user: {
      type: String,
      default: ''
    },
    centerButtons: {
      type: Boolean,
      default: false
    },
    smallButtons: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      btnAttrs: {
        class: 'text-capitalize',
        outlined: true,
        block: true
      },
      open: [],
      prevOpen: [],
      openLoading: false,
      driveUpdated: false,
      icons: [
        { types: ['folder'], icon: 'mdi-folder' },
        { types: ['jpg', 'jpeg'], icon: 'mdi-file-jpg-box' },
        { types: ['png'], icon: 'mdi-file-png-box' }
      ],
      treeData: [],
      detail: null,
      dialogs: {
        createFolder: false,
        uploadFile: false,
        delete: false,
        rename: '',
        preview: false,
        moveTo: false
      },
      pdf: {
        loading: true,
        url: '',
        pages: 0
      },
      folder: '',
      deleteObject: '',
      deleteObjectType: '',
      objectID: '',
      objectType: '',
      loading: false,

      snackbar: {
        show: false,
        message: '',
        type: 'success'
      },
      moveId: '',
      updateCountTimer: null,
      count: 0
    };
  },
  computed: {
    treeDataComputed() {
      if (this.detail) {
        return [
          {
            name: this.detail.name,
            id: 'root',
            type: 'folder',
            children: this.treeData
          }
        ];
      }
      return this.treeData;
    }
  },
  watch: {
    folderId: {
      handler: 'getChildrens',
      immediate: true
    },
    count: {
      handler(value) {
        if (this.updateCountTimer) {
          clearTimeout(this.updateCountTimer);
          this.updateCountTimer = null;
        }
        this.updateCountTimer = setTimeout(() => {
          this.$emit('updateCount', value);
        }, 1000);
      }
    }
  },
  created() {
    this.listenUpdates();
    this.resetCount();
  },
  methods: {
    resetCount() {
      this.count = 0;
    },
    onSuccess() {
      this.dialogs.moveTo = false;
      this.reloadTree();
    },
    showMoveTo({ id }) {
      this.moveId = id;
      this.dialogs.moveTo = true;
    },
    isPdf(item) {
      return getExtension(item.name) === 'pdf';
    },
    preview(item) {
      if (item.type === 'file' && this.isPdf(item)) {
        this.pdf.url = item.url;
        this.dialogs.preview = true;
      }
    },
    hideSnackbar() {
      setTimeout(() => {
        this.snackbar.show = false;
        this.snackbar.message = '';
        this.snackbar.type = 'success';
      }, 5000);
    },
    showSnackbar({ message, type }) {
      this.snackbar.message = message;
      this.snackbar.type = type || 'success';
      this.snackbar.show = true;
      this.hideSnackbar();
    },
    showRename(id, type) {
      this.objectID = id;
      this.objectType = type;
      this.dialogs.rename = true;
    },
    getSize(size) {
      if (!size) {
        return '';
      }
      return filesize(size);
    },
    formatDescription(item) {
      let res = `${item.description} ${this.showFileInfo ? '[' : ''}`;
      // if (this.$store.state.drive.config.showFilesize && this.showFileInfo) {
      if (this.showFilesize && this.showFileInfo) {
        res += `${this.getSize(item.size)} `;
      }
      if (this.showFileInfo) {
        res += `${this.getExtension(item.name)}]`;
      }
      return res;
    },
    getExtension,
    fileSize(size) {
      return filesize(size, { round: 0 });
    },
    treeLoadTimeout() {
      return 2000 + this.prevOpen.length * 1000;
    },
    async confirmDelete() {
      if (this.deleteObjectType === 'folder') {
        await drive.deleteFolder(this.deleteObject);
      } else if (this.deleteObjectType === 'file') {
        await drive.deleteFile(this.deleteObject);
      }
      this.dialogs.delete = false;

      this.driveUpdated = true;
      setTimeout(() => {
        this.driveUpdated = false;
      }, this.treeLoadTimeout());
      this.treeData = [];
      this.getChildrens(this.folderId);
    },
    showDeleteDialog(item) {
      this.deleteObject = item.id;
      this.deleteObjectType = item.type;
      this.dialogs.delete = true;
    },
    listenUpdates() {
      EventBus.$on('loadDrive', () => {
        if (this.driveUpdated) {
          this.openLoading = true;
          this.open = [];
          this.open = JSON.parse(JSON.stringify(this.prevOpen));
          setTimeout(() => {
            this.openLoading = false;
          }, this.treeLoadTimeout());
        }
      });
    },
    onOpenUpdate(value) {
      if (!this.openLoading) {
        this.open = value;
        this.prevOpen = JSON.parse(JSON.stringify(value));
      }
    },
    onClickPrependIcon(item) {
      if (this.$vuetify.breakpoint.xs) {
        this.download(item);
      } else {
        this.preview(item);
      }
    },
    download({ type, url, name, description }, xsOnly = false) {
      if (type === 'folder') {
        return;
      }
      if (xsOnly && this.$vuetify.breakpoint.name !== 'xs') {
        return;
      }
      let filename = name;
      if (description) {
        filename = addExtension(description, getExtension(filename));
      }
      downloadURI(url, filename);
    },
    onCreateFolder() {
      this.driveUpdated = true;
      setTimeout(() => {
        this.driveUpdated = false;
      }, this.treeLoadTimeout());
      this.reloadTree();
      this.folder = '';
    },
    reloadTree() {
      this.resetCount();
      this.treeData = [];
      this.getChildrens(this.folderId);
    },
    onUploadFile() {
      setTimeout(() => {
        this.driveUpdated = false;
      }, 2000);
      this.reloadTree();
      this.folder = '';
    },
    showCreateFolder(id) {
      this.folder = id;
      this.dialogs.createFolder = true;
    },
    showUploadFile(id) {
      this.folder = id;
      this.dialogs.uploadFile = true;
    },
    getIcon({ type, name }) {
      let t = type;
      if (!t) {
        t = name.split('.').pop();
      }
      let icon = 'mdi-file-document-outline';
      this.icons.forEach(i => {
        if (i.types.includes(t)) {
          icon = i.icon;
        }
      });
      if (this.$vuetify.breakpoint.name === 'xs') {
        if (type === 'file') {
          icon = 'mdi-file-download';
        }
      }
      return icon;
    },

    insertData(tree, id, data) {
      if (!id || id === this.folderId) {
        tree.push(...data);
      } else {
        tree.forEach(node => {
          if (node.type === 'folder') {
            if (node.id === id) {
              node.children.push(...data);
            } else {
              this.insertData(node.children, id, data);
            }
          }
        });
      }
    },

    async getChildrens(id) {
      if (this.folderId === id) {
        this.loading = true;
        if (id) {
          const detail = await drive.folderDetail(id);
          this.detail = detail.data();
          setTimeout(() => {
            this.open.push('root');
          }, 500);
        }
      }
      await this.getFolders(id || null);
      this.loading = false;
      await this.getFiles(id || null);
    },

    getFolders(id) {
      return new Promise((resolve, reject) => {
        const compare = (a, b) => {
          if (String(a.name).toLowerCase() < String(b.name).toLowerCase()) {
            return -1;
          }
          if (String(a.name).toLowerCase() > String(b.name).toLowerCase()) {
            return 1;
          }
          return 0;
        };
        drive
          .listFolders({ parentId: id })
          .then(q => {
            let data = [];
            q.forEach(item => {
              data.push({
                ...item.data(),
                id: item.id,
                children: [],
                type: 'folder'
              });
              this.getChildrens(item.id);
            });

            data = sort({
              items: data,
              generator: item => {
                return item.description || item.name;
              }
            });

            this.insertData(this.treeData, id, data);
            EventBus.$emit('loadDrive', id);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    async getFiles(id) {
      let data = [];
      const q = await drive.listFiles({ folderId: id });
      EventBus.$emit('loadDrive', id);

      q.forEach(item => {
        data.push({
          ...item.data(),
          type: 'file',
          id: item.id,
          children: []
        });
      });

      data = sort({
        items: data,
        generator: item => {
          return item.description || item.name;
        }
      });

      this.count += data.length;
      this.insertData(this.treeData, id, data);
    }
  }
};
</script>
<style scoped lang="scss">
.button-upload {
  justify-content: right;
}
.menu__content {
  margin-top: 2rem;
}
</style>
