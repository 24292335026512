/* eslint-disable no-unused-vars */
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import LogRocket from 'logrocket';
import Gallery from '../services/gallery';

FilePond.registerPlugin(FilePondPluginFileValidateSize);
FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginFileValidateType);

const createFilePond = (
  el,
  onProcess,
  onRemove,
  { captureMethod, label } = {},
  { updateLoading } = {}
) => {
  if (!label) {
    label = `Drag &amp; Drop your images or <span class="filepond--label-action" tabindex="0">Browse</span>`;
  }
  const inputElement = document.querySelector(el);
  return FilePond.create(inputElement, {
    captureMethod: captureMethod || null,
    allowFileSizeValidation: true,
    maxFileSize: '20MB',
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/*', 'image/heic'],
    labelIdle: label,

    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options
      ) => {
        try {
          const start = new Date().getTime();
          const id = await Gallery.getImageId();
          if (!id) {
            throw new Error('Error generating Image ID');
          }
          file.id = id;
          await onProcess(file);
          load();
          const end = new Date().getTime();
          LogRocket.track('ImageUploadTime', {
            start,
            end,
            time: (end - start) / 1000 + ' seconds'
          });
        } catch (err) {
          error();
        }
      }
    },
    onaddfilestart: () => {
      if (updateLoading) {
        updateLoading();
      }
    },
    onprocessfile: () => {
      if (updateLoading) {
        updateLoading();
      }
    },
    onremovefile: (_error, file) => {
      onRemove(file.file.id);
      if (updateLoading) {
        updateLoading();
      }
    }
  });
};

export { createFilePond };
