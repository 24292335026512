const combineFeatures = (parcelFeatures, orgFeatures) => {
  let res = orgFeatures || {};

  if (!parcelFeatures) {
    return res;
  }

  Object.keys(parcelFeatures).forEach(feature => {
    res[feature] = parcelFeatures[feature];
  });
  return res;
};

export { combineFeatures };
