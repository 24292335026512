<template>
  <div>
    <v-form>
      <div>
        <v-textarea v-model="description" label="Description" rows="1" auto-grow />
      </div>
      <div>
        <Filepond
          :user="user"
          :folder-id="folderId"
          :total.sync="total"
          :uploaded.sync="uploaded"
          :uploading.sync="uploading"
          :upload-errors.sync="uploadErrors"
          :metadata="metadata"
          @upload="onUpload"
        />
      </div>
      <div class="text-center py-3">
        <FormAction
          submit-label="Close"
          :show-submit="!total || total === uploaded"
          :show-cancel="total !== 0 && total !== uploaded"
          :submit-attrs="{ color: 'success' }"
          @click="onSubmit"
          @cancel="onCancel"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import Filepond from './Filepond.vue';
import FormAction from './FormAction.vue';
import { debounce } from 'throttle-debounce';

import Drive from './drive';

const updateDescription = debounce(500, (ids, description) => {
  ids.forEach(id => {
    const drive = new Drive();
    drive.updateFileData(id, { description });
  });
});

const drive = new Drive();

export default {
  components: {
    Filepond,
    FormAction
  },
  props: {
    otherData:{
    },
    user: {
      type: String,
      required: true
    },
    folderId: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      description: '',
      total: 0,
      uploaded: 0,
      uploadErrors: 0,
      ids: [],
      uploading: false
    };
  },
  computed: {
    metadata(){
      const res = {...(this.otherData||{})}

      res.user = this.user;
      res.folderId = this.folderId;

      return res;
    }
  },
  watch: {
    uploading: {
      handler(value) {
        this.$emit('update:uploading', value);
      },
      immediate: true
    },
    description: {
      handler(value) {
        updateDescription(this.ids, value);
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('close');
    },
    onUpload(fileInfo) {
      this.$emit('update:uploading', true);

      const loggedInAs = this.$store.state.loggedInAs;
      let userId = this.$store.state.user.uid;
      let organizationId = '';
      if (loggedInAs.type === 'organization') {
        organizationId = loggedInAs.id;
      }

      const data = {
        ...fileInfo,
        folderId: this.folderId,
        user: this.user,
        description: this.description,
        userId,
        organization: organizationId,
        otherData: {
          ...(fileInfo || {}),
          ...(this.otherData || {}),
        }
      };

      drive.createFile(data).then(res => {
        this.ids.push(res.id);
        if (!this.uploading) {
          this.$emit('upload');
        }
      });
    }
  }
};
</script>

<style>
</style>
