import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',[_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.email),callback:function ($$v) {_vm.$set(_vm.dialogs, "email", $$v)},expression:"dialogs.email"}},[_c('GrantorEmail',{key:_vm.dialogs.email,attrs:{"parcel-id":_vm.parcelId},on:{"success":function($event){_vm.dialogs.email = false}},model:{value:(_vm.dialogs.email),callback:function ($$v) {_vm.$set(_vm.dialogs, "email", $$v)},expression:"dialogs.email"}})],1),_c(VMenu,{attrs:{"top":"","right":"","transition":"scale-transition","origin":"top left","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('LandChip',{attrs:{"type":_vm.data.type,"x-small":""}})],1)]}}],null,false,801570305),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"max-width":"600"}},[_c(VCardTitle,[_vm._v(" Land Information "),_c(VSpacer),(_vm.permissions.create || _vm.$store.getters.isAdmin)?_c(VBtn,{attrs:{"icon":"","color":"primary","to":("/parcels/" + _vm.parcelId + "/land?information=true")}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_vm._e(),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{staticClass:"system-information"},[_c('p',[_c('land-chip',{attrs:{"type":_vm.data.type}}),(_vm.data.status)?_c(VChip,{staticClass:"ml-1",attrs:{"color":"info","small":""}},[_vm._v(" "+_vm._s(_vm.data.status)+" ")]):_vm._e(),(_vm.data.trails)?_c(VChip,{staticClass:"ml-1",attrs:{"color":"info","small":""}},[_vm._v(" Trails ")]):_vm._e()],1),_vm._l((_vm.fields),function(field){return _c('div',{key:field.value,staticClass:"mb-1"},[(_vm.data[field.value])?_c('span',[_vm._v(" "+_vm._s(field.text)+": "),(field.type === 'chip')?_c(VChip,_vm._b({staticClass:"text-capitalize",attrs:{"color":field.color || 'primary'}},'v-chip',field.attrs,false),[_vm._v(" "+_vm._s(_vm.data[field.value])+" ")]):_c('span',[(field.fmt)?_c('span',[_vm._v(" "+_vm._s(field.fmt(_vm.data[field.value]))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.data[field.value])+" ")])]),(
                field.value === 'grantor' &&
                _vm.contact &&
                (_vm.contact.name ||
                  _vm.contact.email ||
                  (_vm.contact.phone && _vm.contact.phone.length && _vm.contact.phone[0].number)) &&
                _vm.data.grantor !== _vm.contact.name
              )?_c('div',{staticClass:"primary--text"},[_c(VDivider,{staticClass:"my-1"}),_c('strong',[_vm._v(" Contact ")]),_c('div',[(_vm.contact.firstName || _vm.contact.lastName)?_c('div',[_vm._v(" "+_vm._s(_vm.contact.firstName + ' ' + _vm.contact.lastName)+" ")]):_vm._e(),(_vm.contact.email)?_c('div',{staticClass:"primary--text my-2 cursor-pointer",on:{"click":function($event){_vm.dialogs.email = true}}},[_c(VBtn,{attrs:{"color":"primary","icon":""}},[_c(VIcon,[_vm._v("mdi-email")])],1),_vm._v(" "+_vm._s(_vm.contact.email)+" ")],1):_vm._e(),(_vm.contact.phone && _vm.contact.phone.length)?_c('div',_vm._l((_vm.contact.phone),function(phone){return _c('div',{key:phone.number,staticClass:"my-2"},[(phone.number)?_c('CallMessage',{attrs:{"number":phone.number,"type":phone.label}}):_vm._e()],1)}),0):_vm._e(),_c(VDivider,{staticClass:"my-1"})],1)],1):_vm._e()],1):_vm._e()])})],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }