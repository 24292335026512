<template>
  <div>
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text> Are you sure? Do you want to delete this parcel? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteCurrentParcel" :loading="$store.getters.parcelLoading"
            >Yes</v-btn
          ><v-btn text color="secondary" @click="dialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      @click="dialog = true"
      :icon="buttonIcon"
      block
      color="error"
      v-bind="$attrs"
      class="text-capitalize"
    >
      <slot name="text">
        <span v-if="!buttonIcon"> Delete </span>
        <v-icon>mdi-trash-can</v-icon>
      </slot>
    </v-btn>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel';
export default {
  mixins: [parcel],
  props: {
    id: {
      type: String,
      required: true
    },
    goBack: {
      type: Boolean,
      default: false
    },
    buttonIcon: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    async deleteCurrentParcel() {
      this.$emit('update');
      await this.deleteParcel(this.id);
      this.dialog = false;
      if (this.goBack) {
        this.$router.push('/parcel');
      }
      this.logEvent('parcel', { action: 'delete', parcel: this.id, parcelId: this.id });
    }
  }
};
</script>

<style></style>
