<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <div v-if="formData">
      <div v-if="type === 'file'">
        <v-text-field
          v-if="formData"
          v-model="filename"
          :suffix="`.${getExtension(formData.name)}`"
          label="Filename"
        />
        <v-textarea
          v-model="formData.description"
          rows="2"
          label="Description"
        />
      </div>
      <div v-if="type === 'folder'">
        <v-text-field
          v-if="formData"
          v-model="formData.name"
          label="Folder Name"
        />
      </div>
      <AlertMessage :error="error" />
      <FormAction
        submit-label="Update"
        :x-large="false"
        :submit-attrs="{ large: true, color: 'primary' }"
        :cancel-attrs="{ large: true, color: 'primary' }"
        @click="update()"
        @cancel="$emit('input', false)"
      />
    </div>
  </v-form>
</template>

<script>
import Drive from './drive';
import { getExtension, removeExtension, addExtension } from './utils';
import AlertMessage from './AlertMessage.vue';
import FormAction from './FormAction.vue';

const drive = new Drive();

export default {
  components: {
    AlertMessage,
    FormAction,
  },
  props: {
    otherData: {
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      formData: null,
      error: '',
    };
  },
  computed: {
    filename: {
      get() {
        if (this.formData) {
          return removeExtension(this.formData.name);
        }
        return '';
      },
      set(value) {
        this.formData.name = addExtension(
          value,
          this.getExtension(this.formData.name),
        );
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getExtension,
    getRef() {
      const res = { detail: null, update: null };
      if (this.type === 'folder') {
        res.detail = drive.folderDetail;
        res.update = drive.updateFolder;
      } if (this.type === 'file') {
        res.detail = drive.fileDetail;
        res.update = drive.updateFile;
      }
      return res;
    },
    async getData() {
      let res;
      if (this.type === 'file') {
        res = await drive.fileDetail(this.id);
      } else if (this.type === 'folder') {
        res = await drive.folderDetail(this.id);
      }

      if (res.exists) {
        this.formData = res.data();
      }
    },
    async update() {
      const newData = {...this.formData, ...(this.otherData || {})}
      try {
        if (this.type === 'file') {
          await drive.updateFile(this.id, newData);
        } else if (this.type === 'folder') {
          await drive.updateFolder(this.id, newData);
        }
        this.$emit('input', false);
        this.$emit('success');
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style>
</style>
