<template>
  <div>
    <v-dialog v-if="!parcelView" v-model="dialogs.parcel" max-width="600">
      <ParcelDetailById :id="parcelId" show-close @close="dialogs.parcel = false" />
    </v-dialog>

    <v-dialog v-model="dialogs.map" max-width="600" @input="onInput">
      <v-card height="80vh">
        <v-btn
          fab
          x-small
          @click="
            dialogs.map = false;
            dialogs.action = true;
          "
          class="mr-1 mt-1"
          style="position: absolute; right: 0; z-index: 3"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <ParcelMap
          :parcel="parcelGeojson"
          :key="parcelGeojson"
          :parcel-number="parcelNumber"
          :parcel-id="parcelId"
        />
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" v-model="dialogs.action">
      <v-card class="mx-auto">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="dialogs.action = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div class="py-4">
            <v-btn
              block
              text
              color="purple lighten-4"
              class="text-capitalize v-btn--active my-3"
              x-large
            >
              <v-spacer> </v-spacer>
              <span
                @click="
                  dialogs.action = false;
                  takePhoto();
                "
              >
                <v-icon color="purple">mdi-camera</v-icon>
                <span class="purple--text"> Take Photo </span>
              </span>
              <v-spacer> </v-spacer>
              <v-badge
                :value="imagesToday(images)"
                :content="imagesToday(images)"
                overlap
                class="text-none"
              >
                <v-btn
                  icon
                  @click="thumbnailsAction = !thumbnailsAction"
                  color="primary"
                  :class="{ 'v-btn--active': thumbnailsAction }"
                >
                  <v-icon size="20">$galleryGrey</v-icon>
                </v-btn>
              </v-badge>
            </v-btn>
            <div v-if="thumbnailsAction">
              <GalleryView
                :filter="filter"
                :geojson="parcelGeojson"
                :parcel="parcel"
                :galleryRef="galleryRef"
                :category="feature"
                :show-date="gallerySetting.showDate"
                :images="images"
              />
            </div>
            <v-btn
              block
              text
              v-if="showPhotoStation && config.photoStation"
              color="primary lighten-4"
              class="text-capitalize v-btn--active my-3"
              x-large
              @click="onClick"
            >
              <v-icon color="primary">mdi-image-marker</v-icon>
              <span class="primary--text"> Photo station </span>
            </v-btn>
            <v-btn
              v-if="isValidGeojson()"
              text
              block
              x-large
              color="indigo lighten-4"
              class="text-capitalize v-btn--active my-3"
              @click="
                dialogs.action = false;
                showMap();
              "
            >
              <v-icon color="indigo">mdi-map-legend</v-icon>
              <span class="indigo--text"> Parcel Map </span>
            </v-btn>
            <v-btn
              v-if="landActivity"
              block
              text
              x-large
              color="primary lighten-4"
              class="text-capitalize v-btn--active my-3"
              @click="
                dialogs.action = false;
                showLand();
              "
            >
              <v-spacer></v-spacer>
              <v-icon color="primary">mdi-plus</v-icon>
              <span class="primary--text"> Land Activity </span>
              <v-spacer></v-spacer>
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
              v-else
              block
              x-large
              color="primary"
              class="text-capitalize my-3"
              @click="
                dialogs.action = false;
                showLand();
              "
            >
              <v-spacer></v-spacer>
              <v-icon>mdi-plus</v-icon>
              <span> Land Activity </span>
              <v-spacer></v-spacer>
              <v-icon> mdi-checkbox-blank-circle-outline </v-icon>
            </v-btn>

            <v-btn
              block
              text
              x-large
              color="primary lighten-4"
              class="text-capitalize v-btn--active my-3"
              @click="
                dialogs.action = false;
                showParcelCard();
              "
            >
              <v-icon color="primary">mdi-card-text</v-icon>
              <span class="primary--text">Parcel Card</span>
            </v-btn>
            <div class="d-flex flex-wrap align-center justify-space-between">
              <v-btn
                text
                x-large
                color="secondary lighten-4"
                class="text-capitalize v-btn--active"
                style="width: 49%"
                @click="
                  dialogs.action = false;
                  showListView();
                "
              >
                <v-icon color="secondary"> mdi-table-of-contents </v-icon>
                <span class="secondary--text"> List view </span>
              </v-btn>
              <v-btn
                text
                x-large
                style="width: 49%"
                color="secondary lighten-4"
                class="text-capitalize v-btn--active"
                @click="
                  dialogs.action = false;
                  showMapView();
                "
              >
                <v-icon color="secondary"> mdi-map </v-icon>
                <span class="secondary--text"> Map view </span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="photoStation" fullscreen>
      <v-card height="100%" flat style="overflow: hidden">
        <div class="photostation-close">
          <v-btn @click="photoStation = false" fab small class="mt-2 mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <PhotoStationMap :parcel-geojson="parcelGeojson" :images="images" @click="showDetail">
          <template #control-bottom-right>
            <v-menu
              eager
              v-model="dialogs.photoStationDateFilter"
              :close-on-content-click="false"
              max-width="300"
              top
              offset-x
            >
              <template #activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-card class="mx-auto">
                <v-card-title class="mb-0 pb-0">
                  Show photos
                  <v-spacer></v-spacer>
                  <v-btn @click="dialogs.photoStationDateFilter = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-container>
                  <DateFilter
                    :from.sync="filter.from"
                    :to.sync="filter.to"
                    :dates="activeDates(allImages)"
                  />
                </v-container>
              </v-card>
            </v-menu>
          </template>
        </PhotoStationMap>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog max-width="600" v-model="dialogs.share">
        <v-card class="mx-auto">
          <v-card-title>
            <v-spacer>{{ mode === 'camera' ? 'Take Photo' : 'Add Images' }}</v-spacer>
            <v-btn icon @click="dialogs.share = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-subtitle>
            <slot name="subtitleUpload">
              <ParcelGalleryTitle :parcel="parcelData" :feature="feature" />
            </slot>
          </v-card-subtitle>
          <v-container>
            <GalleryAddForm
              :geojson="parcelGeojson"
              :mode="mode"
              :key="dialogs.share"
              :value="dialogs.share"
              :logged-in="true"
              :parcel-number="parcelNumber"
              :storage="storage"
              :categories="categories"
              :user-setting="userSetting"
              :gallery-ref="galleryRef"
              :parcel-id="parcelId"
              photo-required
              :user-data="userData"
              :selected-category="selectedCategory"
              :gallery="gallery"
              :fields="fields"
              :data="data"
              @cancel="dialogs.share = false"
              @success="onSuccess"
            />
          </v-container>
        </v-card>
      </v-dialog>
      <div class="my-1">
        <GalleryView
          ref="gallery"
          :filter="filter"
          :geojson="parcelGeojson"
          :parcel="parcel"
          :galleryRef="galleryRef"
          :category="feature"
          :show-date="gallerySetting.showDate"
          :images.sync="images"
          :all-images.sync="allImages"
          @update:images="onUpdateImages"
          @update:count="onUpdateCount"
        />
      </div>
      <div class="d-flex align-center pb-4">
        <v-btn
          v-if="showPhotoStation && config.photoStation"
          @click="onClick"
          :color="photoStation ? 'primary' : ''"
          icon
          :class="photoStation ? 'v-btn--active' : ''"
        >
          <v-icon color="primary"> mdi-image-marker </v-icon>
        </v-btn>
        <v-menu max-width="350" :close-on-content-click="false" v-model="dialogs.galleryFilter">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card class="mx-auto">
            <v-card-title>
              Show photos
              <v-spacer></v-spacer>
              <v-icon @click="dialogs.galleryFilter = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <DateFilter
                :from.sync="filter.from"
                :to.sync="filter.to"
                :dates="activeDates(allImages)"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <v-spacer></v-spacer>
        <div>
          <base-prop-tooltip
            v-if="count"
            :tooltip="gallerySetting.showDate ? 'Hide date' : 'Show date'"
            color="primary"
          >
            <v-btn
              class="mr-1 text-capitalize"
              small
              text
              @click="toggleDate()"
              :color="gallerySetting.showDate ? 'primary' : ''"
              :class="{ 'v-btn--active': gallerySetting.showDate }"
            >
              <v-icon>mdi-calendar</v-icon></v-btn
            >
          </base-prop-tooltip>
          <v-btn
            color="primary"
            small
            @click="
              mode = '';
              dialogs.share = true;
            "
            :outlined="!$vuetify.breakpoint.xs"
            :text="$vuetify.breakpoint.xs"
            :class="{ 'v-btn--active': $vuetify.breakpoint.xs }"
            class="text-capitalize"
          >
            <span v-if="!$vuetify.breakpoint.xs"> Add Images </span>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            @click="
              mode = 'camera';
              dialogs.share = true;
            "
            small
            :icon="!$vuetify.breakpoint.xs"
            :text="$vuetify.breakpoint.xs"
            :class="{ 'v-btn--active': $vuetify.breakpoint.xs }"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config';
import GalleryAddForm from './GalleryAddForm.vue';
import GalleryView from './GalleryView';
import { storage } from '@/services/storage';
import { galleryRef } from '@/services/firestore';
import { iconGalleryMap } from '../../components/icons';
import { parseGeoJSON } from '../../utils/map';

import { mapGetters } from 'vuex';
import moment from 'moment';
import center from '@turf/center';
import audioMixin from '../../mixins/audio';
import Land from '../../services/land';
export default {
  mixins: [audioMixin],
  props: {
    parcelGeojson: {
      type: [String, Object],
      default: null
    },
    parcelData: {
      type: Object,
      default: null
    },
    parcelNumber: {
      type: String,
      required: true
    },
    parcel: {
      type: String,
      required: true
    },
    parcelId: {
      type: String,
      required: true
    },
    feature: {
      type: String,
      default: 'Parcel'
    },
    showPhotoStation: {
      type: Boolean,
      default: false
    }
  },

  components: {
    GalleryAddForm,
    GalleryView,
    ParcelGalleryTitle: () => import('../Parcel/GalleryTitle.vue'),
    PhotoStationMap: () => import('../Parcel/PhotoStationMap.vue'),
    DateFilter: () => import('../DateFilter.vue'),
    ParcelMap: () => import('../../components/Parcel/Map.vue'),
    ParcelDetailById: () => import('../../components/Parcel/DetailById.vue')
  },
  computed: {
    parcelView() {
      return ['map', 'parcels', 'list'].includes(this.$route.path.split('/').pop());
    },
    ...mapGetters({
      user: 'user',
      gallerySetting: 'gallerySetting'
    })
  },
  watch: {
    user: {
      handler(val) {
        this.userData.email = val.email;
        this.userData.firstName = val.profile.first_name;
        this.userData.lastName = val.profile.last_name;
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.destroy();
    }
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      config,
      filter: {
        from: moment().subtract(90, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      images: [],
      allImages: [],
      storage,
      dialogs: {
        share: false,
        photoStationDateFilter: false,
        galleryFilter: false,
        action: false,
        map: false,
        parcel: false
      },
      map: null,
      mode: '',
      categories: [],
      userSetting: { gps: true },
      galleryRef,
      userData: {
        email: '',
        firstName: '',
        lastName: ''
      },
      data: {
        parcelId: this.parcel
      },
      selectedCategory: this.feature,
      gallery: {
        name: 'Peeps',
        id: config.galleryID()
      },
      fields: ['caption', 'subcaption', 'artDescription', 'imageUpload', 'parcel'],
      count: 0,
      photoStation: false,
      iconGalleryMap,
      unsubscribe: null,
      landActivity: false,
      thumbnailsAction: false
    };
  },
  mounted() {
    this.fetchLandTransactions();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    activeDates(images) {
      const res = new Set();
      if (!images) {
        return [];
      }
      images.forEach(image => {
        try {
          const year = moment(image.imageDate || image.createdAt.toDate()).format('YYYY-MM-DD');
          res.add(year);
        } catch {
          //
        }
      });
      return [...res];
    },
    imagesToday(images) {
      let res = images || [];
      res = res.filter(i => {
        const date = i?.createdAt?.toDate();
        if (date) {
          return moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
        }
        return false;
      });
      const count = res.length;
      if (count > 0) {
        return `${count} today`;
      }
      return count || (images || []).length;
    },
    fetchLandTransactions() {
      const l = new Land();
      l.listActivity(this.parcelId, null, data => {
        const latest = data[0];
        if (
          latest &&
          latest.createdAt &&
          moment(latest.createdAt.toDate()).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        ) {
          this.landActivity = true;
        } else {
          this.landActivity = false;
        }
      }).then(unsubscribe => {
        this.unsubscribe = unsubscribe;
      });
    },
    setParcelDetail(key, value) {
      this.$store.commit('setParcelDetail', { key, value });
    },
    showParcelCard() {
      if (this.parcelView) {
        this.setParcelDetail('dialog', true);
        this.setParcelDetail('id', this.parcelId);
      } else {
        this.dialogs.parcel = true;
      }
    },
    onInput(value) {
      if (!value) {
        this.dialogs.action = true;
      }
    },
    showActionDialog() {
      this.dialogs.action = true;
    },
    isValidGeojson() {
      try {
        const { type } = parseGeoJSON(this.parcelGeojson);
        return type === 'Feature' || type === 'FeatureCollection';
      } catch {
        return false;
      }
    },
    showMapView() {
      let url = '/parcels/map';
      let coordinates;
      try {
        coordinates = center(parseGeoJSON(this.parcelGeojson)).geometry.coordinates.join(',');
        url += `?center=${coordinates}`;
      } catch {
        //
      }
      this.$router.push(url);
    },
    showListView() {
      this.$router.push('/parcels/list');
    },
    showMap() {
      this.dialogs.map = true;
    },
    takePhoto() {
      this.mode = 'camera';
      this.dialogs.share = true;
    },
    showLand() {
      this.$store.commit('setParcelDetail', { key: 'id', value: '' });
      this.$store.commit('setParcelDetail', { key: 'dialog', value: false });
      this.$emit('click:land', this.landActivity);
    },
    onClick() {
      this.photoStation = !this.photoStation;
      // this.$emit('update:photoStation', this.photoStation);
    },
    onUpdateImages(images) {
      this.$emit('update:images', images);
    },
    showDetail(id) {
      this.$refs.gallery.showDetail({ id });
    },
    onSuccess() {
      this.dialogs.share = false;
      this.dialogs.action = true;
      this.playBell();
      this.$emit('upload');
    },
    onUpdateCount(count) {
      this.count = count;
      this.$emit('updateCount', count);
      this.$emit('update:count', count);
    },
    toggleDate() {
      const value = !this.gallerySetting.showDate;
      this.$store.dispatch('updateGallerySetting', {
        key: 'showDate',
        value
      });
    }
  }
};
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.photostation-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  // .btn {
  //   margin-top: 5px;
  //   margin-right: 5px;
  // }
}
.map {
  height: 100%;
  width: 100%;
  position: relative;
}
.btn-close-map {
  z-index: 999;
}
</style>
