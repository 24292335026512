import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('septic-system-chip',{attrs:{"componentType":_vm.data.component_type}})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"min-width":"400","max-width":"600"}},[_c(VCardTitle,[_vm._v(" System Information "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary","to":("/parcels/" + _vm.parcelId + "/septic?septic_card=true")}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{staticClass:"system-information"},[(_vm.data.component_type)?_c('p',[_vm._v(" Component Type: "+_vm._s(_vm.getSepticComponent(_vm.data.component_type))+" ")]):_vm._e(),(_vm.data.installed && Object.keys(_vm.data.installed).length)?_c('p',[_c('span',[_vm._v("Installed")]),_c('br'),(_vm.data.installed.by)?_c('span',{staticClass:"ml-5"},[_vm._v(" By: "+_vm._s(_vm.data.installed.by)+" ")]):_vm._e(),_c('br'),(_vm.data.installed.year)?_c('span',{staticClass:"ml-5"},[_vm._v(" Year: "+_vm._s(_vm.data.installed.year)+" ")]):_vm._e()]):_vm._e(),(_vm.data.pumped && Object.keys(_vm.data.pumped).length)?_c('p',[_c('span',[_vm._v("Pumped")]),_c('br'),(_vm.data.pumped.by)?_c('span',{staticClass:"ml-5"},[_vm._v(" By: "+_vm._s(_vm.data.pumped.by)+" ")]):_vm._e(),_c('br'),(_vm.data.pumped.year)?_c('span',{staticClass:"ml-5"},[_vm._v(" Year: "+_vm._s(_vm.data.pumped.year)+" ")]):_vm._e()]):_vm._e(),(
            _vm.data.cleanout_location_description ||
            (_vm.data.cleanout_location && _vm.data.cleanout_location.sketch)
          )?_c('p',[_c('span',[_vm._v(" Cleanout Location ")]),_c('br'),(_vm.data.cleanout_location_description)?_c('span',{staticClass:"ml-5"},[_vm._v(" Description: "+_vm._s(_vm.data.cleanout_location_description)+" ")]):_vm._e(),_c('br'),(_vm.data.cleanout_location.sketch.exists)?_c('span',{staticClass:"ml-5"},[_vm._v(" Sketch: "),_c('a',{attrs:{"href":_vm.data.cleanout_location.sketch.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.data.cleanout_location.sketch.filename))])]):_vm._e()]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }