<template>
  <div>
    <v-dialog v-model="dialogs.edit" max-width="600">
      <v-card class="mx-auto">
        <v-card-title>
          Edit Image
          <v-spacer></v-spacer>
          <v-btn @click="dialogs.edit = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <GalleryUpdate
            :id="gallery.id"
            :geojson="geojson"
            :key="gallery.id"
            :show-images="false"
            @success="onUpdate"
            @cancel="dialogs.edit = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.delete" max-width="400">
      <base-confirmation
        v-model="dialogs.delete"
        confirm-message="Delete this photo?"
        @cancel="dialogs.delete = false"
        @confirm="deleteGallery()"
      >
        <template #head>
          <alert-message :error="errors.delete"></alert-message>
        </template>
      </base-confirmation>
    </v-dialog>

    <v-card min-width="300" max-width="500">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="gallery">
        <div>
          <span v-if="gallery.parcelId">
            <strong>Parcel ID:</strong> {{ gallery.parcelId }}<br />
          </span>
          <span v-if="gallery.imageDate">
            <strong>Image Date:</strong> {{ gallery.imageDate }}<br />
          </span>
          <span v-if="gallery.createdAt">
            <strong> Shared: </strong>
            {{ formatDate(gallery.createdAt.toDate()) }}
          </span>
          <span v-if="gallery.updatedAt && loggedIn">
            <br />
            <strong> Modified: </strong>
            {{ formatDate(gallery.updatedAt.toDate()) }}
          </span>

          <span v-if="gallery.files[activeImage] && gallery.files[activeImage].size">
            <br />
            <strong>Size:</strong>
            {{ formatSize(gallery.files[activeImage].size) }}
          </span>

          <div class="d-block d-sm-none"></div>

          <span v-if="dim" class="ml-sm-3 ml-0">
            <strong>H x W:</strong> {{ dim.height }} x {{ dim.width }}
          </span>

          <span v-if="gallery.files && gallery.files.length > 1">
            <br />
            <strong>Images:</strong>
            {{ gallery.files.length }}</span
          >
          <span v-if="gallery.firstName || gallery.lastName">
            <br />
            <strong>By:</strong> {{ fullName(gallery) }}
          </span>
          <span v-if="gallery.views && gallery.views > 1">
            <br />
            <strong>Views: </strong> {{ gallery.views + 1 }}
          </span>
          <copy-text :btn-attrs="{ small: true }" :text="gallery.images[activeImage]">
            <template #label>
              <strong>Image ID: </strong>
            </template>
          </copy-text>
          <div
            v-if="
              gallery.geolocation && gallery.geolocation.latitude && gallery.geolocation.longitude
            "
          >
            <strong>GPS Location: </strong>
            <span>
              {{ getLatitude }},
              {{ getLongitude }}
            </span>
          </div>
          <div class="my-2 text-center">
            <v-btn icon color="primary" @click="downloadPhoto()" v-if="isOwnerOrAdmin">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              icon
              class="text-capitalize"
              color="primary"
              @click="dialogs.edit = true"
              v-if="editable"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn color="error" icon @click="dialogs.delete = true" v-if="editable"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Moment from 'moment';
import filesize from 'filesize';
import CopyText from './CopyText.vue';
import GalleryUpdate from './GalleryUpdate.vue';
import { downloadURI } from '../Drive/utils';
export default {
  components: {
    CopyText,
    GalleryUpdate,
    AlertMessage: () => import('@/components/Core/AlertMessage.vue')
  },
  props: {
    geojson: {},
    gallery: {
      type: Object,
      default: null
    },
    activeImage: {
      type: Number,
      default: 0
    },
    loggedIn: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    userEmail: {
      type: String,
      default: ''
    },
    galleryRef: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogs: {
        edit: false,
        delete: false
      },
      errors: {
        delete: ''
      },
      dim: null
    };
  },
  computed: {
    getLatitude() {
      let res = ``;
      if (this.gallery) {
        if (this.gallery.files[this.activeImage] && this.gallery.files[this.activeImage].location) {
          res = this.toFixed(this.gallery.files[this.activeImage].location.latitude, 5);
        } else {
          res = this.toFixed(this.gallery.geolocation.latitude, 5);
        }
      }
      return res;
    },
    getLongitude() {
      let res = ``;
      if (this.gallery) {
        if (this.gallery.files[this.activeImage] && this.gallery.files[this.activeImage].location) {
          res = this.toFixed(this.gallery.files[this.activeImage].location.longitude, 5);
        } else {
          res = this.toFixed(this.gallery.geolocation.longitude, 5);
        }
      }
      return res;
    },
    isOwner() {
      return this.gallery?.user === this.$store.state.user.uid;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isOwnerOrAdmin() {
      return this.isOwner || this.isAdmin;
    }
  },
  watch: {
    activeImage: {
      handler: 'getImageDimension',
      immediate: true
    }
  },
  methods: {
    downloadPhoto() {
      const image = this.gallery.files[this.activeImage];
      downloadURI(image.url, image.name);
    },
    fullName(obj) {
      if (!obj || typeof obj !== 'object') {
        return '';
      }
      return `${obj.firstName || ''} ${obj.lastName || ''}`;
    },
    formatDate(date, fmt = 'std') {
      if (!date) {
        return '';
      }
      if (fmt === 'std') {
        fmt = 'YYYY-MM-DD h:m:s A';
      }
      if (!date || date === 'Invalid Date') {
        return '';
      }
      return Moment(date).format(fmt);
    },
    formatSize(size) {
      if (!size) {
        return '';
      }
      return filesize(size);
    },
    getImageDimension() {
      let url;
      try {
        url = this.gallery.files[this.activeImage].url;
      } catch {
        url = '';
      }
      if (!url) {
        return;
      }
      this.imageDimension(url)
        .then(dim => {
          this.dim = dim;
        })
        .catch(err => {
          this.handleException(err);
        });
    },
    imageDimension(url) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        const i = new Image();
        i.src = url;
        i.onload = () => {
          resolve({
            height: i.height,
            width: i.width
          });
        };
      });
    },
    async deleteGallery() {
      try {
        await this.galleryRef.doc(this.gallery.id).delete();
        this.dialogs.detail = false;
        this.dialogs.delete = false;
        this.$emit('delete');
      } catch (error) {
        if (error.message) {
          this.errors.delete = error.message;
        } else {
          this.errors.delete = this.$store.state.ERROR_MESSAGES.default;
        }
        error.message = `${error.message}\nError deleting gallery`;
      }
    },
    onUpdate() {
      this.dialogs.edit = false;
      this.$emit('updated');
    }
  }
};
</script>

<style></style>
