<template>
  <div>
    <span
      ><slot name="label"> {{ label }}</slot></span
    >
    <slot v-bind:text="text">
      <span id="target">{{ text }}</span>
      <slot name="after-text"></slot>
    </slot>
    <div class="btn-container d-inline">
      <slot name="activator" :v-bind="on">
        <v-btn
          id="activator"
          v-clipboard:copy="text"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          text
          color="primary"
          v-bind="btnAttrs"
          >{{ btnText }}
          <v-icon>mdi-clipboard-text-multiple-outline</v-icon></v-btn
        >
      </slot>
      <v-fade-transition>
        <div v-if="copied" class="text-center">
          <span class="success--text">{{ successMessage }}</span>
        </div>
      </v-fade-transition>
      <v-fade-transition>
        <div v-if="error" class="text-center">
          <span class="success--text">{{
            $store.state.ERROR_MESSAGES.default
          }}</span>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: 'Copy'
    },
    text: {
      type: String,
      default: ''
    },
    btnAttrs: {
      type: Object
    },
    successMessage: {
      type: String,
      default: 'Image ID Copied'
    }
  },
  data() {
    return {
      copied: false,
      error: false
    }
  },
  methods: {
    on() {
      alert('ON')
    },
    onCopy() {
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    onError() {
      this.error = true
      setTimeout(() => {
        this.error = false
      }, 3000)
    }
  }
}
</script>
