import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Upload File "),_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploading),expression:"!uploading"}],attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('upload-file-form',{attrs:{"other-data":_vm.otherData,"uploading":_vm.uploading,"folder-id":_vm.parentId,"user":_vm.user},on:{"update:uploading":function($event){_vm.uploading=$event},"upload":function($event){return _vm.$emit('upload')},"close":function($event){return _vm.$emit('input', false)}}})],1),_c('div',{staticClass:"text-center py-3"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }